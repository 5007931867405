import React, {useEffect, useState} from 'react'
import ShipmentReport from "./shipment-report/ShipmentReport"
import ProductionReport from "./production-report/ProductionReport"
import classNames from "classnames"
import {getListSuppliersV2} from "../../../services/api/SuppliersServices";
import DailyReport from "./daily-report/DailyReport";
import TiktokOrderReport from './tiktok-order-report/TiktokOrderReport';
import FastProductionReport from './fast-production-report/FastProductionReport';
import CutoffReport from './cutoff-report/CutoffReport';

function StaticsPage(props) {
    const [tab, setTab] = useState(localStorage['tab'] ||'daily')
    const [suppliers, setSuppliers] = useState([])

    const _generateContentTab = (key) => {
        switch (key) {
            case 'cutoff':
                return <CutoffReport />
            case 'shipment':
                return <ShipmentReport />
            case 'production':
                return <ProductionReport suppliers={suppliers} />
            case 'tiktok':
                return <TiktokOrderReport suppliers={suppliers} />
            case 'fastproduction':
                return <FastProductionReport suppliers={suppliers} />
            default:
                return <DailyReport page={props.page} />
        }
    }

    const _setTab = (value) => {
        setTab(value)
    }
    
    async function _fetchSuppliers() {
        try {
            const responseSub = await getListSuppliersV2({active: true})

            const {success: successSub, data: dataSub, message: messSub} = responseSub

            if (!successSub) {
                return console.log(messSub)
            }

            const {suppliers} = dataSub
            setSuppliers(suppliers)
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        _fetchSuppliers()
    }, [])

    useEffect(()=>{
        window.localStorage['tab'] = tab
    })
    // const fixHead = tab === 'daily' || tab === 'production' || tab === 'tiktok' ? 'fixedHeader' : ''
    const fixHead = tab === 'daily' || tab === 'production' ? 'fixedHeader' : ''

    return (
        <div className={`StaticsPage ${fixHead}`}>
            <div className="tabsStatics" id='tabsStatics'>
                <ul>
                    <li onClick={() => _setTab('daily')}
                        className={classNames('itemTab', tab === 'daily' && 'active')}>Daily Report
                    </li>
                    <li onClick={() => _setTab('shipment')}
                        className={classNames('itemTab', tab === 'shipment' && 'active')}>Shipment Report
                    </li>
                    <li onClick={() => _setTab('production')}
                        className={classNames('itemTab', tab === 'production' && 'active')}>Production Report
                    </li>
                    <li onClick={() => _setTab('tiktok')}
                        className={classNames('itemTab', tab === 'tiktok' && 'active')}>Tiktok Order Report
                    </li>
                    <li onClick={() => _setTab('fastproduction')}
                        className={classNames('itemTab', tab === 'fastproduction' && 'active')}>FAST PRODUCTION REPORT
                    </li>
                    <li onClick={() => _setTab('cutoff')}
                        className={classNames('itemTab', tab === 'cutoff' && 'active')}>CutOff REPORT
                    </li>
                </ul>
            </div>
            <div className="contentTab">
                {_generateContentTab(tab)}
            </div>
        </div>
    )
}

export default StaticsPage
