import React from 'react'
import moment from 'moment'
import { Input, DatePicker, Select, Button } from 'antd'
import { MenuOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

const CutoffSettingRow = (props) => {
    const CUTOFF_DATE_FORMAT = 'DD/MM/YYYY HH:mm'
    const {
        total,
        index,
        preCutoff,
        currentCutoffSetting,
        cutoffItem,
        addSetting,
        indexSetting,
        cutoffDateError,
        expiredHolidayDate,
        disabledCutoffDate,
        handleChangeSetting,
        onRemoveSettingCutoff,
        timeZone,
    } = props

    const { cutoff_time, store = {}, product_type = {}, priority, isSave } = Object.assign({}, cutoffItem)
    const { target: targetStore = "allow" } = Object.assign({}, store)
    const { target: targetProductType = "allow" } = Object.assign({}, product_type)
    const cutoffDate = cutoff_time ? moment(cutoff_time).utcOffset(0) : null
    const currentCutoffDate = !!currentCutoffSetting.cutoff_time ? moment(currentCutoffSetting.cutoff_time).utcOffset(0) : null
    const storeSelect = Object.keys(store).map(key => {
        if ((!!store[key] && !!store[key] && Array.isArray(store[key]) && store[key].length > 0)) {
            return {
                field: key,
                value: store[key] || []
            }
        }
    }).filter(Boolean)

    const productTypeSelect = Object.keys(product_type).map(key => {
        if ((!!product_type[key] && !!product_type[key] && Array.isArray(product_type[key]) && product_type[key].length > 0)) {
            return {
                field: key,
                value: product_type[key] || []
            }
        }
    }).filter(Boolean)


    const storeValue = ((!!storeSelect[0] && !!storeSelect[0].value)) ? storeSelect[0].value : []
    const productTypeValue = ((!!productTypeSelect[0] && !!productTypeSelect[0].value)) ? productTypeSelect[0].value : []
    const displayedOptionsStore = store.isExpanded ? storeValue : storeValue.slice(0, 3)
    const displayedOptionsProductType = product_type.isExpanded ? productTypeValue : productTypeValue.slice(0, 3)

    const today = moment().utc().add(timeZone, 'hours').toISOString()
    const disableCutoffDate = isSave && moment(today).isAfter(moment(cutoffDate))
    const cutoffDateSetting = moment(cutoffDate).subtract(Number(preCutoff), "days")
    const disableDeleteCutoffDate = isSave && moment(today).isBetween(cutoffDateSetting, cutoffDate)
    const nearCutoffDate = isSave && moment(today).isAfter(cutoffDate)

    return (
        <React.Fragment key={`settingCutoff_${indexSetting}`}>
            <td className='cutoff-date border-left'>
                <div className='d-flex align-items-center'>
                    <MenuOutlined className='cursor-grab mr-2' />
                    <DatePicker
                        allowClear
                        onChange={(value, dateString) => handleChangeSetting(index, 'cutoff_time', value, priority)}
                        value={cutoffDate}
                        placeholder='Cutoff date'
                        format={CUTOFF_DATE_FORMAT}
                        showTime={{
                            hideDisabledOptions: true,
                            format: 'HH:mm',
                            defaultValue: moment('23:59', 'HH:mm'),
                        }}
                        style={{ width: '100%' }}
                        disabled={disableCutoffDate || expiredHolidayDate}
                        disabledDate={current => disabledCutoffDate(current, currentCutoffDate)}
                    />
                </div>
                {cutoffDateError &&
                    <small className='text-danger'>{cutoffDateError}</small>
                }
            </td>
            <td className='store'>
                <Input.Group compact>
                    <Select
                        value={targetStore}
                        onChange={value => handleChangeSetting(index, 'store', value, priority, {}, true)}
                        style={{
                            width: '25%',
                        }}
                        className="select1"
                        disabled={disableCutoffDate || expiredHolidayDate}
                    >
                        <Select.Option value="allow">Accept</Select.Option>
                        <Select.Option value="disallow">Except</Select.Option>
                    </Select>
                    <Select
                        style={{
                            width: '75%',
                        }}
                        className={`select2 ${!store.isExpanded ? 'isDelete' : null}`}
                        mode="tags"
                        placeholder="Select store"
                        dropdownStyle={{ display: 'none' }}
                        allowClear
                        showArrow={false}
                        tokenSeparators={[',', ' ']}
                        value={displayedOptionsStore}
                        onChange={value => handleChangeSetting(index, 'store', value, priority, targetStore)}
                        onFocus={() => handleChangeSetting(index, 'storeExpand', true, priority, {})}
                        // onBlur={() => handleChangeSetting(index, 'storeExpand', false, priority, {})}
                        disabled={disableCutoffDate || expiredHolidayDate}
                    />
                    {storeValue.length > 3 &&
                        <Button
                            type="link"
                            style={{ marginLeft: 8, float: 'right', clear: 'both' }}
                            onClick={() => handleChangeSetting(index, 'storeExpand', !store.isExpanded, priority, {})}
                        >
                            {store.isExpanded ? 'Show less' : 'Show more'}
                        </Button>
                    }
                </Input.Group>
            </td>
            <td className='product-type position-relative'>
                <div className="d-flex align-items-center">
                    <Input.Group compact>
                        <Select
                            value={targetProductType}
                            onChange={value => handleChangeSetting(index, 'product_type', value, priority, {}, true)}
                            style={{
                                width: '25%',
                            }}
                            className='select1'
                            disabled={disableCutoffDate || expiredHolidayDate}
                        >
                            <Select.Option value="allow">Accept</Select.Option>
                            <Select.Option value="disallow">Except</Select.Option>
                        </Select>
                        <Select
                            style={{
                                width: '75%',
                            }}
                            value={displayedOptionsProductType}
                            onChange={value => handleChangeSetting(index, 'product_type', value, priority, targetProductType)}
                            onFocus={() => handleChangeSetting(index, 'productTypeExpand', true, priority, {})}
                            // onBlur={() => handleChangeSetting(index, 'productTypeExpand', false, priority, {})}
                            placeholder="Select product type"
                            mode='tags'
                            className={`select2 ${!product_type.isExpanded ? 'isDelete' : null}`}
                            dropdownStyle={{ display: 'none' }}
                            tokenSeparators={[',', ' ']}
                            allowClear
                            showArrow={false}
                            disabled={disableCutoffDate || expiredHolidayDate}
                        />
                        {productTypeValue.length > 3 &&
                            <Button
                                type="link"
                                style={{ marginLeft: 8, float: 'right', clear: 'both' }}
                                onClick={() => handleChangeSetting(index, 'productTypeExpand', !product_type.isExpanded, priority, {})}
                            >
                                {product_type.isExpanded ? 'Show less' : 'Show more'}
                            </Button>
                        }
                    </Input.Group>
                    {
                        (!nearCutoffDate && total > 1 && !disableDeleteCutoffDate) &&
                        <MinusCircleOutlined
                            className="ml-2 cursor-pointer position-absolute"
                            style={{ right: -10 }}
                            onClick={() => onRemoveSettingCutoff(index, priority)}
                        />
                    }

                    {
                        (!expiredHolidayDate && priority === total) &&
                        <PlusCircleOutlined
                            className='ml-2 cursor-pointer position-absolute'
                            style={{ right: !(!nearCutoffDate && total > 1 && !disableDeleteCutoffDate) ? -10 : -30 }}
                            onClick={() => addSetting(true, index)}
                        />
                    }

                </div>
            </td>
        </React.Fragment>
    )
}

export default CutoffSettingRow