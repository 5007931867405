import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getListPackage } from '../../../../services/api/SuppliersServices'
import { Modal, message, Tooltip, Table } from 'antd'
import StatusColumn from '../../../order-partners-v2/components/data/StatusColumn'

function ReportCutoffTimeRow(props) {
    const { item } = { ...props }
    const { supplierId, supplierName, holidayReport = [] } = { ...item }

    const getTotalReport = (completed, due, notPush, processing) => {
        return completed + due + notPush + processing
    }

    const getPercentageReport = (completed, total) => {
        return total > 0 ? Math.round((completed / total * 100) * 100) / 100 : 0
    }

    const _convertTime = (shipping_carrier, string, field) => {
        if (!!string) {
            if (shipping_carrier && shipping_carrier === 'HW YUN' && field === 'lastUpdate') {
                return `${moment(string).utcOffset(0).format('HH:mm DD/MM/YYYY')} (location time)`
            }
            return moment(string).format('HH:mm DD/MM/YYYY')
        } else {
            return ''
        }
    }

    const columns = [
        {
            title: 'Info',
            dataIndex: 'info',
            render: info => <>
                <div className="PackageName">
                    <strong>
                        <a href={`/a/orders-supplier/${info._id}`} target='_blank'>{info.name}</a>
                    </strong>
                </div>
                <div className="StoreName">
                    <Tooltip title="Store ID" placement='right'>{!!info.namespace ? info.namespace : ''}</Tooltip>
                </div>
                <div className="OrderReference">
                    <Tooltip title="Order Reference ID" placement='right'>
                        {info.order_reference_id}
                    </Tooltip>
                </div>
                <div className="PackagePushTime">
                    <Tooltip title="Date Push At" placement='right'>
                        {_convertTime(info.shipping_carrier, info.pushed_at)}
                    </Tooltip>
                </div>
                <div className="CustomReference">
                    {info.customReference
                    }
                </div>
            </>,
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            render: supplier => <>
                {
                    !!supplier.name &&
                    <div
                        className='supplier'
                    >
                        <span className='cursor-pointer'>{supplier.name}</span>
                    </div>

                }
                <p className="text-muted small">{supplier.shipping_carrier || 'Other'}</p>
            </>
        },
        {
            title: 'Package Status',
            dataIndex: 'package_status',
            render: package_status => <>
                {!!package_status.package_status &&
                    <StatusColumn
                        status={package_status.package_status || ''}
                        metaData={package_status.factory_info.metadata || ''}
                        isShowReject={true}
                        isShowTags={true}
                        did_actions={package_status.did_actions}
                    // allowedUpdateTags={allowedUpdateTags}
                    />
                }
                {package_status.did_actions.length > 0 &&
                    <div className='Tags'>
                        {package_status.did_actions.map(i => (
                            <span className='Tag badge badge-secondary mr-1' style={{ background: 'rgb(255, 88, 88)' }}>
                                {/* {allowedUpdateTags[i]} */}
                            </span>
                        ))}
                    </div>
                }
            </>
        },
        {
            title: 'Production Status',
            dataIndex: 'production_status',
            render: production_status => <>
                {
                    (production_status !== undefined && production_status.status !== null) &&
                    <StatusColumn status={production_status.status || ''} metaData={production_status.metadata || ''}
                        orderNote={production_status.order_status_note} />
                }
            </>
        },
        {
            title: 'Due',
            dataIndex: 'due',
        },
        {
            title: 'Tracking',
            dataIndex: 'tracking',
            render: tracking => <>
                {(tracking.hasOwnProperty('tracking_number') && tracking.tracking_number !== '') && (
                    <a rel="noopener noreferrer" target="_blank"
                        href={tracking.tracking_url}>{tracking.tracking_number || ''}</a>
                )}
                <div>{tracking.tracking_company || ''}</div>
                <div>{tracking.tracking_status || ''}</div>
                <div>{_convertTime(tracking.tracking_updated_at)}</div>
                {
                    tracking.isTrackingModified &&
                    <div><span className='tracking-modified'>Modified</span></div>
                }
            </>
        },
    ];


    const toggleModal = async (i, status) => {
        const { holiday = {}, report = {} } = { ...i }
        const { cutoff_tags = [] } = { ...holiday }
        const payload = {}
        if (supplierId) payload.supplier = supplierId
        if (status) payload.status = status
        payload.tags = cutoff_tags.length > 0 ? cutoff_tags : ['cutoff']

        try {
            const { success, message: mess, data: res } = await getListPackage(payload)
            if (!success) {
                throw new Error(mess)
            }
            const { data = [] } = { ...res }
            const dataSource = data.map((i, idx) => {
                const {
                    order, supplier, factory_info, package_status,
                    pushed_type, production_overtime, did_actions = [],
                    box_tracking_info, shipping_carrier, last_mile_tracking_info,
                    namespace, pushed_at, tracking_number, tracking_url, tracking_company,
                    tracking_status, tracking_updated_at,
                } = { ...i }
                const isTrackingModified = Array.isArray(did_actions) && did_actions.includes('tracking_modified')
                return (
                    {
                        key: idx,
                        info: {
                            _id: i._id,
                            namespace: i.namespace,
                            name: i.name,
                            order_reference_id: factory_info.order_reference_id,
                            shipping_carrier: shipping_carrier,
                            pushed_at: pushed_at,
                            customReference: factory_info !== undefined && !!factory_info.metadata &&
                                (factory_info.metadata.customer_reference || factory_info.metadata.CustomerPo || factory_info.metadata.mc_order_id || '')
                        },
                        supplier: {
                            name: supplierName,
                            shipping_carrier: shipping_carrier,
                        },
                        package_status: {
                            package_status: package_status,
                            factory_info: factory_info,
                            did_actions: did_actions
                        },
                        production_status: factory_info,
                        due: production_overtime,
                        tracking: {
                            tracking_number: tracking_number,
                            tracking_url: tracking_url,
                            tracking_company: tracking_company,
                            tracking_status: tracking_status,
                            tracking_updated_at: tracking_updated_at,
                            isTrackingModified: isTrackingModified
                        },
                    }
                )
            })
            Modal.info({
                title: 'List Package',
                width: '80%',
                okText: 'Đóng',
                content: (
                    <div>
                        <div className="wrapTable">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={{
                                    pageSizeOptions: [3, 5, 10, 20, 50, 100],
                                    showSizeChanger: true, showQuickJumper: true,
                                    total: dataSource.length, defaultPageSize: 3,
                                }}
                            />
                        </div>
                    </div>
                ),
                destroyOnClose: true,
                className: 'ModalListPackage',
            })
        } catch (error) {
            message.error(error.message)
        }
    }

    return (
        holidayReport.length > 0 ? holidayReport.map((i, index) => (
            <tr key={`reportCutoffRow_${index}`}>
                {index === 0 &&
                    <>
                        <td rowSpan={holidayReport.length}>
                            {supplierName}
                        </td>
                    </>
                }
                <td>
                    {/* <div>{i.holiday.name || '-'}</div> */}
                    <div>
                        {i.holiday.start_time ? moment(i.holiday.start_time).utcOffset(0).format('DD/MM/YYYY') : '-'}
                        {i.holiday.start_time && i.holiday.end_time ? ` - ${moment(i.holiday.end_time).utcOffset(0).format('DD/MM/YYYY')}` : ''}
                    </div>
                </td>
                <td>
                    {/* {getTotalReport(i.report.completed, i.report.due, i.report.notPush, i.report.processing)} */}
                    <Tooltip title='List Package'>
                        <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(i, '')}>
                            {i.report.total}
                        </span>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip title='List Package'>
                        <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(i, 'completed')}>
                            {i.report.completed}
                        </span>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip title='List Package'>
                        <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(i, 'processing')}>
                            {i.report.processing}
                        </span>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip title='List Package'>
                        <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(i, 'due')}>
                            {i.report.due}
                        </span>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip title='List Package'>
                        <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(i, 'notPush')}>
                            {i.report.notPush}
                        </span>
                    </Tooltip>
                </td>
                <td>
                    {getPercentageReport(i.report.completed, getTotalReport(i.report.completed, i.report.due, i.report.notPush, i.report.processing))} %
                </td>
            </tr>
        )) : ''
    )
}

export default ReportCutoffTimeRow