import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import moment from 'moment'


function SettingHolidayRowItems(props) {
    const { totalIndex, id, name, item, totalRow } = { ...props }
    const [showMoreStore, setShowMoreStore] = useState(false)
    const [showMoreSku, setShowMoreSku] = useState(false)
   
    const convertUTC = (so) => {
        const gio = Math.floor(so)
        const phut = (so - gio) * 60

        const chuoi = `${so >= 0 ? 'UTC +' : 'UTC -'}${Math.abs(gio).toString().padStart(2, '0')}:${phut < 10 ? '0' : ''}${phut}`
        return chuoi
    }

    const toggleShowMoreStore = () => {
        setShowMoreStore(!showMoreStore)
    }

    const toggleShowMoreSku = () => {
        setShowMoreSku(!showMoreSku)
    }

    return (
        item.cutoff_settings && item.cutoff_settings.length > 0 ? item.cutoff_settings.map((i, index) => {
            const storeArray = i.store && i.store.allow.length > 0 ? i.store.allow : i.store.disallow
            // const storeArray = []
            const skuArray = i.product_type && i.product_type.allow.length > 0 ? i.product_type.allow : i.product_type.disallow
            // const skuArray = []
            
            return (
                <tr key={`splitBox_${index}`} className={`rowSplitBox ${totalIndex > 0 || index > 0 ? 'topDashed' : ''}`}>
                    {totalIndex === 0 && index === 0 &&
                        <>
                            <td rowSpan={totalRow}>
                                {name}
                            </td>
                        </>
                    }
                    {
                        index === 0 &&
                        <>
                        <td rowSpan={item.cutoff_settings.length}>{item.name || '-'}</td>
                        <td rowSpan={item.cutoff_settings.length}>
                            {item.start_time ? moment(item.start_time).utcOffset(0).format('DD/MM/YYYY') : '-'}
                            {item.start_time && item.end_time ? ` - ${moment(item.end_time).utcOffset(0).format('DD/MM/YYYY')}` : ''}
                        </td>
                        </>
                    }
                    
                    <td>{i.cutoff_time ? moment(i.cutoff_time).utcOffset(0).format('DD/MM/YYYY') : '-'}</td>
                    {
                        storeArray.length < 4 ? <td className={`storeColumn ${i.store.disallow.length > 0 ? 'text-danger' : ''}`}>{storeArray.length > 0 ? storeArray.join(', ') : '-'}</td> : <td className='storeColumn'>
                            {
                                !showMoreStore ? <>
                                {
                                    storeArray.slice(0, 4).join(', ')
                                }

                                <span className='show-more-text cursor-pointer ml-2 text-primary' onClick={toggleShowMoreStore}>(More)</span>
                                </> : <>
                                {
                                    storeArray.join(', ')
                                }
                                
                                <span className='show-more-text cursor-pointer ml-2 text-primary' onClick={toggleShowMoreStore}>(Hide)</span>
                                </>
                            }
                        </td>
                    }

                    {
                        skuArray.length < 4 ? <td className={`skuColumn ${i.product_type.disallow.length > 0 ? 'text-danger' : ''}`}>{skuArray.length > 0 ? skuArray.join(', ') : '-'}</td> : <td className='skuColumn'>
                            {
                                !showMoreSku ? <>
                                {
                                    skuArray.slice(0, 4).join(', ')
                                }

                                <span className='show-more-text cursor-pointer ml-2 text-primary' onClick={toggleShowMoreSku}>(More)</span>
                                </> : <>
                                {
                                    skuArray.join(', ')
                                }
                                
                                <span className='show-more-text cursor-pointer ml-2 text-primary' onClick={toggleShowMoreSku}>(Hide)</span>
                                </>
                            }
                        </td>
                    }

                    {totalIndex === 0 && index === 0 &&
                        <>
                            <td rowSpan={totalRow}>{totalRow > 0 && item.pre_cutoff_days ? item.pre_cutoff_days : '-'}</td>
                            <td rowSpan={totalRow}>{totalRow > 0 && item.time_zone ? convertUTC(item.time_zone) : '-'}</td>
                            <td>
                                <Link to={`/a/setting-holiday/${id}`}>
                                    <Tooltip title={`Edit`} placement="right">
                                        <i className="fa fa-pencil fa-lg text-primary mr-2" />
                                    </Tooltip>
                                </Link>
                            </td>
                        </>
                    }
                </tr>
            )
        }) : <tr className={`rowSplitBox ${totalIndex > 0 ? 'topDashed ssssssssss' : ''}`}>
            {totalIndex === 0 &&
                <>
                    <td rowSpan={totalRow}>
                        {name}
                    </td>
                </>
            }
            {
                <>
                <td>{item.name || '-'}</td>
                <td>
                    {item.start_time ? moment(item.start_time).utcOffset(0).format('DD/MM/YYYY') : '-'}
                    {item.start_time && item.end_time ? ` - ${moment(item.end_time).utcOffset(0).format('DD/MM/YYYY')}` : ''}
                </td>
                </>
            }
            
            <td>{item.cutoff_time ? moment(item.cutoff_time).utcOffset(0).format('DD/MM/YYYY') : '-'}</td>
            <td>-</td>
            <td>-</td>

            {totalIndex === 0 &&
                <>
                    <td rowSpan={totalRow}>{totalRow > 0 && item.pre_cutoff_days ? item.pre_cutoff_days : '-'}</td>
                    <td rowSpan={totalRow}>{totalRow > 0 && item.time_zone ? convertUTC(item.time_zone) : '-'}</td>
                    <td>
                        <Link to={`/a/setting-holiday/${id}`}>
                            <Tooltip title={`Edit`} placement="right">
                                <i className="fa fa-pencil fa-lg text-primary mr-2" />
                            </Tooltip>
                        </Link>
                    </td>
                </>
            }
        </tr>
    )
}

export default SettingHolidayRowItems