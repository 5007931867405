import React, {Component} from 'react'
import {Button, Modal} from "antd"
import PropTypes from 'prop-types'
import broker from '../../../OrderBroker'
import {pushOrderPackages} from "../../../../../services/api/ExportOrderServices"
import {getLocalData} from '../../../../../services/StorageServices'

class PushAllPackages extends Component {
    state = {
        loading: false,
        visible: false,
        error: '',
        pushResults: []
    }

    _toggleConfirm = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    _closeConfirm = () => {
        this.setState({
            visible: false,
            error: '',
            pushResults: []
        })
    }

    _handlePushAll = async () => {
        const {orderId, fulfillment} = this.props
        const packageIds = fulfillment.map(itemPackage => itemPackage._id)
        const name1C = getLocalData(`exportGgDrv.name.1c`, '')
        const link1C = getLocalData(`exportGgDrv.link.1c`, '')
        const nameOther = getLocalData(`exportGgDrv.name.other`, '')
        const linkOther = getLocalData(`exportGgDrv.link.other`, '')
        const nameBasegift = getLocalData(`exportGgDrv.name.basegift`, '')
        const linkBasegift = getLocalData(`exportGgDrv.link.basegift`, '')

        const supliers = [
            {'sku_prefix': '1C', 'sheet_url': link1C, 'sheet_name': name1C},
            {'sku_prefix': 'BG', 'sheet_url': linkBasegift, 'sheet_name': nameBasegift},
            {'sheet_url': linkOther, 'sheet_name': nameOther},
        ]

        this.setState({
            loading: true,
            error: ''
        })

        const payload = {
            fulfillment_ids: packageIds,
            supplier_sheets: supliers
        }

        try {
            const {success, data, message} = await pushOrderPackages(orderId, payload)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            const {push_results} = data

            this.setState({
                loading: false,
                pushResults: push_results
            }, () => {
                broker.publish("TRACKING_UPDATED")
                broker.publish("ORDER_REFRESH_ORDER")
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    render() {
        const {fulfillment, code, paymentStatus} = this.props
        const {loading, visible, error, pushResults} = this.state
        const disabled = paymentStatus === 'refunded'

        return (
            <>
                <button className="btn btn-primary ml-2" disabled={disabled} onClick={this._toggleConfirm}>Push all package
                </button>
                {visible && (
                    <Modal
                        visible={visible}
                        title={`Order #${code}`}
                        onOk={this._handlePushAll}
                        onCancel={this._closeConfirm}
                        footer={[
                            <div className="d-flex justify-content-between align-items-center">
                                <Button key="back" onClick={this._closeConfirm}>
                                    Cancel
                                </Button>
                                {
                                    (pushResults.length > 0 || error !== '') ? (
                                        <Button loading={loading} key="submit" type="primary"
                                                onClick={this._closeConfirm}>
                                            Close
                                        </Button>
                                    ) : (
                                        <Button loading={loading} key="submit" type="primary"
                                                onClick={this._handlePushAll}>
                                            OK
                                        </Button>
                                    )
                                }
                            </div>,
                        ]}
                    >
                        <div>
                            {pushResults.length > 0 ? (
                                <div>
                                    <strong>Results:</strong>
                                    <ul className="mt-2 mb-3">
                                        {
                                            pushResults.map(pushItem => {
                                                const {fulfillment: fulfillmentItem, error_message, success} = pushItem
                                                const {supplier, _id, name} = fulfillmentItem
                                                return <li key={_id} className="mb-2">
                                                <span className="d-flex justify-content-between align-items-center">
                                                    <span>Package <strong>{name}</strong>: <strong>[{supplier.name}]</strong></span>
                                                    <span>{success ?
                                                        <span className="badge badge-success">success</span> :
                                                        <span className="badge badge-danger">error</span>}</span>
                                                </span>
                                                    <span className="text-danger">{error_message || ''}</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            ) : (
                                <div>
                                    <strong>Do you want to push this order to production?</strong>
                                    <ul className="mt-2 mb-3">
                                        {
                                            fulfillment.map(packageItem => {
                                                const {supplier, _id, name} = packageItem
                                                return <li
                                                    key={_id}>Package <strong>{name}</strong>: <strong>[{supplier.name}]</strong>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            )}

                        </div>
                        {error && <div className="mb-3 text-danger">{error}</div>}
                    </Modal>
                )}
            </>
        )
    }
}

PushAllPackages.propTypes = {
    fulfillment: PropTypes.array.isRequired,
    code: PropTypes.string.isRequired,
    paymentStatus:PropTypes.string
}

export default PushAllPackages