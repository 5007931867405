import React, {useState, useEffect} from "react"
import {Modal, Input, message, Button, Tooltip} from "antd"
import {exportAllVariantsPrice, exportVariants} from "../../../services/api/ExportOrderServices"
import {getLocalData, setLocalData} from '../../../services/StorageServices'
import FilterOptions from "../../shared/FilterOptions"
import {searchProducts} from "../../../services/api/ProductsServices"
import {
    SyncOutlined,
} from '@ant-design/icons'

const Success = message.success
const localStorageKey = '8dd9d9b466121eed3c27d9c56e460de2'
const ExportAllVariants = (prop) => {
    const storedLinks = getLocalData(localStorageKey)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [linkGG, setLinkGG] = useState(storedLinks && storedLinks[0] || '')
    const [sheetName, setSheetName] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [isLoadedProducts, setLoadedProducts] = useState(false)
    const effectReloadProducts = () => {
        setLoadedProducts(false)
        fetchProducts().then(res => {
            setLoadedProducts(true)
        })
    }

    useEffect(() => {
        setLinkGG(storedLinks && storedLinks[0] || '')
    }, [isModalVisible])

    useEffect(effectReloadProducts, [])

    const showModal = () => {
        setIsModalVisible(true)
    }

    const fetchProducts = async () => {
        try {
            const {data, success} = await searchProducts({limit: 10000})

            if (!success) {
                return
            }

            const products = data.products.map(it => ({
                name: it.type,
                value: it._id
            }))

            setAllProducts(products)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const maybeUpdateUrl = () => {
        let newLink = linkGG
        const list = getLocalData(localStorageKey) || []
        const index = list.indexOf(linkGG)

        if (index > -1) {
            list.splice(index, 1)
        }

        list.unshift(newLink)

        if (list.length > 5) {
            list.splice(5)
        }

        setLocalData(localStorageKey, list)
    }

    const handleExport = async () => {
        if (!`${linkGG}`.match(/^https?:\/\//)) {
            return setErrors({linkGG: 'Google Sheet url is invalid'})
        }

        maybeUpdateUrl()

        setLoading(true)
        let err = {}
        const payload = {link: linkGG, name: sheetName, query: {}}

        if (selectedSuppliers.length) {
            payload.query.supplier = selectedSuppliers
        }

        if (selectedProducts.length) {
            payload.query.product = selectedProducts
        }

        const response = await exportAllVariantsPrice(payload).catch(e => {
            return {
                success: false,
                message: e.message
            }
        })
        const {success, message} = response

        if (response) {
            setLoading(false)
        }

        if (success === false) {
            err.message = message
        } else {
            setIsModalVisible(false)
            setLinkGG("")
            setSheetName("")
            Success('Export successfully!!!')
        }
        setErrors(err)
    }

    const handleCancel = () => {
        setLinkGG("")
        setSheetName("")
        setErrors({})
        setIsModalVisible(false)
    }

    const onChangeFilter = (e) => {
        if (e.supplier) {
            setSelectedSuppliers([...e.supplier])
        } else if (e.product) {
            setSelectedProducts([...e.product])
        }
    }

    const reloadProducts = (e) => {
        e.preventDefault()
        effectReloadProducts()
    }

    const productHeading = () => {
        return <div className="d-flex">
            <span>Product (optional)</span>
            <span className="ml-auto d-inline-block">
                {
                    isLoadedProducts && <a href="" onClick={reloadProducts}><SyncOutlined/></a>
                }
                {
                    !isLoadedProducts && <SyncOutlined spin/>
                }
            </span>
        </div>
    }

    return (
        <>
            <Tooltip title={'Export all variants with price by store'} placement={"bottom"}>
                <Button type="default" onClick={showModal}>Export All</Button>
            </Tooltip>
            <Modal
                title={"Export List Variants"}
                visible={isModalVisible}
                okText={'Export'}
                onOk={handleExport}
                confirmLoading={loading}
                onCancel={handleCancel}
                width={800}
            >
                {errors && <small style={{color: "#ed4122", display: "block"}}>{errors.message}</small>}
                <div>
                    <label className="font-weight-bold">Link</label>
                    <Input value={linkGG} onChange={e => {
                        setLinkGG(e.target.value)
                    }} className="mb-2" allowClear autoFocus={true}/>
                    {errors && <small style={{color: "#ed4122", display: "block"}}>{errors.linkGG}</small>}
                    {errors && <small style={{color: "#ed4122", display: "block"}}>{errors.sheetName}</small>}
                    <p className="mb-0">
                        Share your Google Sheet to <b>everybody</b> or bellow account:
                        <b>exporter@platform126.iam.gserviceaccount.com</b>
                    </p>
                    <p style={{color: '#ed4122', fontSize: 'smaller'}} className="mb-4"><i>(The content of current file
                        will be
                        reset)</i></p>

                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={selectedSuppliers}
                                   field='supplier'
                                   options={prop.suppliers.map(s => ({name: s.name, value: s._id}))}
                                   layout='select'
                                   heading='Supplier (optional)'
                                   defaultOption={''}
                                   allowClear={true}
                                   showSearch={true}
                                   mode="multiple"
                                   className="mb-4"
                    />

                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={selectedProducts}
                                   field='product'
                                   options={allProducts}
                                   layout='select'
                                   heading={productHeading()}
                                   defaultOption={''}
                                   allowClear={true}
                                   showSearch={true}
                                   mode="multiple"
                                   loading={!isLoadedProducts}
                    />
                    {/*{*/}
                    {/*    storedLinks && !!storedLinks.length && <ul>*/}
                    {/*        {*/}
                    {/*            storedLinks.map(link => {*/}
                    {/*                return <li >*/}
                    {/*                    {link}*/}
                    {/*                </li>*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </ul>*/}
                    {/*}*/}
                </div>
            </Modal>
        </>
    )
}

export default ExportAllVariants