import React from 'react'
import { Tooltip } from 'antd'

function SettingHolidayDetailHeader(props) {
    const { handleSort, sortType, listSettingDetail } = { ...props }

    return (
        <thead className="SettingHolidayDetailHeader">
            <tr>
                <th className='holiday-name'>Holiday Name</th>
                <th className='holiday-date'>Holiday Date
                    {listSettingDetail && listSettingDetail.length > 0 &&
                        <Tooltip
                            className={`${!sortType || sortType === 'down' ? 'ti-arrow-up' : 'ti-arrow-down'} cursor-pointer due Sort ml-3`}
                            title={!sortType || sortType === 'down' ? 'Sort up' : 'Sort down'}
                            onClick={() => handleSort(!sortType || sortType === 'down' ? 'up' : 'down')}
                        />
                    }
                </th>
                <th className='cutoff-date'>Cutoff Date</th>
                <th className='store'>Store</th>
                <th className='product-type'>Product Type</th>
                <th className='actions'>Actions</th>
            </tr>
        </thead>
    )
}

export default SettingHolidayDetailHeader