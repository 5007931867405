import React, { Component, Fragment } from 'react'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ProductLineCategories from "./ProductLineCategories"
import ProductLineTitleSearch from "./ProductLineTitleSearch"
import ProductLineModalNavigation from "./ProductLineModalNavigation"
import ProductLineItems from "./ProductLineItems"
import ProductLineVariants from "./ProductLineVariants"
import PropTypes from 'prop-types'
import ProductSource from "./ProductSource"
import { getProductAttributes } from '../../../../services/api/ProductsServices'

class ProductLineModalContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedProduct: "",
            filter: {
                storeModes: [props.storeMode],
                currency: (props.orderItem !== undefined && props.orderItem.hasOwnProperty('currency')) && props.orderItem.currency === 'VND' ? props.orderItem.currency : 'USD',
                term: "",
                category: "",
                supplier: "",
                limit: 100,
                page: 1,
                available_tiktok: "",
            },
            filteredSupplierTiktok: false,
            filteredAvaikabeTiktok: false,
            selectionSize: '',
            selectionColor: '',
            attributes: [],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedProduct } = this.state
        if (!!selectedProduct && selectedProduct !== prevState.selectedProduct) {
            this._fetchProductAttributes()
        }
    }

    _fetchProductAttributes = async () => {
        try {
            const { selectedProduct } = this.state
            const { data, success, message } = await getProductAttributes(selectedProduct)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                attributes: [...data],
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }


    _handUpdateState = (field, data) => {
        this.setState({
            [field]: data,
        })
    }

    _ToggleModal = () => {
        const {
            storeMode,
            orderItem,
            _handleToggleModal,
        } = this.props
        _handleToggleModal()
        this.setState({
            selectedProduct: "",
            filter: {
                storeModes: [storeMode],
                currency: (orderItem !== undefined && orderItem.hasOwnProperty('currency')) && orderItem.currency === 'VND' ? orderItem.currency : 'USD',
                term: "",
                category: "",
                supplier: "",
                limit: 100,
                page: 1,
                available_tiktok: "",
            },
            filteredSupplierTiktok: false,
            filteredAvaikabeTiktok: false
        }
        )
    }

    render() {
        const {
            selectedProduct, filter, filteredSupplierTiktok, filteredAvaikabeTiktok,
            selectionSize, selectionColor, attributes,
        } = this.state
        const {
            orderId,
            showModal,
            _handleToggleModal,
            orderItemId,
            _handleUpdateStateOrderItem,
            orderItem,
            storeMode,
            artworks,
            _handleAlert,
            artworksMore,
            categories,
            error,
            namespace,
            orderType,
            order,
            listSuppliers,
        } = this.props
        const { category } = filter

        return (
            <div className="ProductLineModalContainer">
                <Modal isOpen={showModal} toggle={this._ToggleModal} className="ProductLineModal">
                    <ModalBody>
                        <ProductLineModalNavigation
                            selectedProduct={selectedProduct}
                            _handleToggleModal={this._ToggleModal}
                            _handUpdateState={this._handUpdateState}
                        />

                        <div className="row">
                            <div className="col-2">
                                <ProductSource
                                    orderId={orderId}
                                    storeMode={storeMode}
                                    artworks={artworks}
                                    artworksMore={artworksMore}
                                    orderItem={orderItem}
                                    orderItemId={orderItemId}
                                    error={error}
                                    _handUpdateState={this._handUpdateState}
                                    filter={filter}
                                    attributes={attributes}
                                    selectedProduct={selectedProduct}
                                />
                            </div>
                            <div className="col-10">
                                {
                                    !!selectedProduct ?
                                        <ProductLineVariants
                                            orderId={orderId}
                                            selectedProduct={selectedProduct}
                                            orderItemId={orderItemId}
                                            _handleUpdateStateOrderItem={_handleUpdateStateOrderItem}
                                            filter={filter}
                                            listSuppliers={listSuppliers}
                                            artworks={artworks}
                                            _handleAlert={_handleAlert}
                                            namespace={namespace}
                                            order_type={order.order_type}
                                            selectionSize={selectionSize}
                                            selectionColor={selectionColor}
                                            _handUpdateState={this._handUpdateState}
                                            attributes={attributes}
                                        />
                                        :
                                        <Fragment>
                                            <ProductLineTitleSearch
                                                order={order}
                                                filter={filter}
                                                _handUpdateState={this._handUpdateState}
                                                listSuppliers={listSuppliers}
                                                orderType={orderType}
                                                showModal={showModal}
                                                filteredSupplierTiktok={filteredSupplierTiktok}
                                                filteredAvaikabeTiktok={filteredAvaikabeTiktok}
                                            />

                                            <ProductLineCategories
                                                categoryId={category}
                                                categories={categories}
                                                filter={filter}
                                                _handUpdateState={this._handUpdateState}
                                            />

                                            <ProductLineItems
                                                filter={filter}
                                                _handUpdateState={this._handUpdateState}
                                            />
                                        </Fragment>
                                }
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

ProductLineModalContainer.propTypes = {
    orderId: PropTypes.string,
    showModal: PropTypes.bool.isRequired,
    _handleToggleModal: PropTypes.func.isRequired,
    orderItemId: PropTypes.string.isRequired,
    _handleUpdateStateOrderItem: PropTypes.func.isRequired,
    storeMode: PropTypes.string.isRequired
}

export default ProductLineModalContainer

