import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import moment from 'moment'
import SettingHolidayRowItems from './SettingHolidayRowItems'


function SettingHolidayRow(props) {
    const { item } = { ...props }
    const { _id, name, holidays = [] } = { ...item }

    const convertUTC = (so) => {
        const gio = Math.floor(so)
        const phut = (so - gio) * 60

        const chuoi = `${so >= 0 ? 'UTC +' : 'UTC -'}${Math.abs(gio).toString().padStart(2, '0')}:${phut < 10 ? '0' : ''}${phut}`
        return chuoi
    }

    const totalRow = holidays.reduce((accumulator ,item) => {
        return accumulator += item.cutoff_settings && item.cutoff_settings.length > 0 ? item.cutoff_settings.length : 1;
    }, 0)
    
    return (
        holidays.length > 0 ? holidays.map((i, index) => {
            return <SettingHolidayRowItems key={`splitBox_${index}`} totalIndex={index} id={_id} name={name} item={i} totalRow={totalRow}/>
        }
        ) : <tr>
            <td>{name || '-'}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
                <Link to={`/a/setting-holiday/${_id}`}>
                    <Tooltip title={`Edit`} placement="right">
                        <i className="fa fa-pencil fa-lg text-primary mr-2" />
                    </Tooltip>
                </Link>
            </td>
        </tr>
    )
}

export default SettingHolidayRow