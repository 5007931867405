import React, { useState, useEffect, useRef, useMemo } from 'react'
import '../styles/_index.scss'
import { Link } from "react-router-dom"
import { message, Button, Radio, Select, Tooltip, Switch, Modal } from "antd"
import { getCatalogVariantDetail, updateCatalogVariantDetail } from '../../../services/api/MappingVariantDetailServices'
import { getVariants, searchProducts } from '../../../services/api/ProductsServices'
import MappingVariantHistory from './MappingVariantHistory'
import getHistory from '../../../store/getHistory'
import { getLocalData } from '../../../services/StorageServices'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function MappingVariantDetail(props) {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [optionsProductType, setOptionsProductType] = useState([])
    const [dataDroplist, setDataDroplist] = useState([])
    const [catalogVariant, setCatalogVariant] = useState({})
    const defaultProductLine = [
        {
            ffm_variant: '',
            productType: '',
            visibleDroplist: false,
            sku: '',
            supplierSku: '',
            variantSupplier: '',
            mode: '',
            supplier: '',
            fulfillmentStatus: null,
            add_note_when_mapping: null,
            priority: 1
        }
    ];
    // React state to track order of items

    const [listProductLineBefore, setListProductLineBefore] = useState([]);
    const [listProductLine, setListProductLine] = useState(defaultProductLine);
    const [validateProductLine, setValidateProductLine] = useState([])
    const dropdownRef = useRef([])
    const inputRef = useRef([])
    const inputSupplierRef = useRef([])

    const _fetchListOptionsProductType = async () => {
        try {
            const { data, success } = await searchProducts({ limit: 10000 })
            if (!success) return

            const newListProducts = data.products.map(item => item.type).filter(item => !!item)
            const listProductsFinal = []
            newListProducts.map(item => {
                if (!listProductsFinal.includes(item)) {
                    listProductsFinal.push(item)
                }
            })

            const listOptionsProductType = listProductsFinal.map(item => {
                return {
                    label: item,
                    value: item
                }
            })
            setOptionsProductType(listOptionsProductType)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const _fetchDataDroplist = async ({ productType, sku, supplierSku, variant_status }) => {
        try {
            const payload = {
                limit: 200,
                page: 1,
                query: {}
            }

            if (productType) {
                payload.query.exact_product_type = productType
            }

            if (sku) {
                payload.query.sku = sku
            }

            if (supplierSku) {
                payload.query.partner_sku = supplierSku
            }

            if (variant_status) {
                payload.query.variant_status = variant_status
            }

            const { data, success } = await getVariants(payload)

            if (!success) return

            const dataDroplist = data.variants.filter(variant => variant.product && variant.product.is_deleted === false)
            setDataDroplist(dataDroplist)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    const _fetchDataDroplistSupplierSku = async ({ productType, sku, supplierSku, variant_status }) => {
        try {
            const payload = {
                limit: 200,
                page: 1,
                query: {},
                onlyFindWithPartnerSku: 1
            }

            if (productType) {
                payload.query.exact_product_type = productType
            }

            if (sku) {
                payload.query.sku = sku
            }

            if (supplierSku) {
                payload.query.partner_sku = supplierSku
            }

            if (variant_status) {
                payload.query.variant_status = variant_status
            }

            const { data, success } = await getVariants(payload)

            if (!success) return

            const dataDroplist = data.variants.filter(variant => variant.product && variant.product.is_deleted === false)
            setDataDroplist(dataDroplist)
        } catch (error) {
            console.log(error.message || 'Unknown error')
        }
    }

    async function _getDetailCatalogVariant() {
        try {
            const { match } = props
            const { catalog_id } = match.params

            const { success, message, data } = await getCatalogVariantDetail(catalog_id)

            if (!success) {
                setLoading(false)
                return message.error(error)
            }

            setLoading(false)
            setCatalogVariant(data)
            if (data.mapping_status === 'mapped') {
                const productLineNew = data.mapping_items.map((item, index) => {
                    const { add_note_when_mapping: addNote } = { ...item }
                    const productLineItem = {
                        ffm_variant: item.ffm_variant._id,
                        productType: item.ffm_product_type ? item.ffm_product_type : '',
                        visibleDroplist: false,
                        sku: item.ffm_sku ? item.ffm_sku : '',
                        supplierSku: item.ffm_partner_sku ? item.ffm_partner_sku : '',
                        variantSupplier: item.ffm_variant && item.ffm_variant.title ? item.ffm_variant.title : '',
                        mode: item.store_mode ? item.store_mode.join('/') : '',
                        supplier: item.supplier && item.supplier.name ? item.supplier.name : '',
                        fulfillmentStatus: item.ffm_variant.is_disabled,
                        add_note_when_mapping: addNote || false,
                        priority: item.priority ? item.priority : index + 1
                    }

                    return productLineItem
                })

                let newFormValues = [...productLineNew, ...listProductLine];
                newFormValues[newFormValues.length - 1].priority = newFormValues.length
                setListProductLine(newFormValues)

                setListProductLineBefore(JSON.parse(JSON.stringify(newFormValues)))
            }

        } catch (e) {
            setLoading(false)

            message.error(e.message)
        }
    }

    useEffect(() => {
        _fetchListOptionsProductType()
        // _fetchDataDroplist({})
        _getDetailCatalogVariant()
        // eslint-disable-next-line
    }, [])

    // click away listener
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, [listProductLine]);

    const _onSelectOption = (index, itemSelected) => {
        let newFormValues = [...listProductLine];
        newFormValues[index]['ffm_variant'] = itemSelected._id;
        newFormValues[index]['productType'] = itemSelected.product.type ? itemSelected.product.type : '';
        newFormValues[index]['sku'] = itemSelected.sku ? itemSelected.sku : '';
        newFormValues[index]['supplierSku'] = itemSelected.partner_sku ? itemSelected.partner_sku : '';
        newFormValues[index]['variantSupplier'] = itemSelected.options.map(option => option.name).join('/');
        newFormValues[index]['mode'] = itemSelected.product.store_modes ? itemSelected.product.store_modes.join(', ') : '';
        newFormValues[index]['supplier'] = itemSelected.supplier && itemSelected.supplier.name ? itemSelected.supplier.name : '';
        newFormValues[index]['fulfillmentStatus'] = itemSelected.is_disabled;
        newFormValues[index]['add_note_when_mapping'] = itemSelected.add_note_when_mapping || true;
        // newFormValues[index]['priority'] = itemSelected.priority ? itemSelected.priority : '';
        newFormValues[index]['visibleDroplist'] = false
        setListProductLine(newFormValues)
        setValidateProductLine([])
    }

    const handleClick = e => {
        const indexShow = listProductLine.findIndex(item => item.visibleDroplist === true)
        if (indexShow >= 0) {
            let newFormValues = [...listProductLine];
            if (e && (e.target === inputRef.current[indexShow] || dropdownRef.current[indexShow].contains(e.target) || e.target === inputSupplierRef.current[indexShow])) {
                newFormValues[indexShow]['visibleDroplist'] = true;
            } else {
                newFormValues[indexShow]['visibleDroplist'] = false;
            }

            setListProductLine(newFormValues)
        }
    };

    const _onClickInput = (index, e) => {
        let newFormValues = [...listProductLine];
        // console.log('click 11111111111111')
        if (e && (e.target === inputRef.current[index] || e.target === inputSupplierRef.current[index])) {
            // console.log('click 222222222222222222')
            newFormValues[index]['visibleDroplist'] = true;
        } else {
            newFormValues[index]['visibleDroplist'] = false;
        }

        setListProductLine(newFormValues)

        const payload = {
            productType: newFormValues[index]['productType'],
            sku: e.target.value,
            // variant_status: "1"
        }

        _fetchDataDroplist(payload)
    }

    const _onClickInputSupplierSku = (index, e) => {
        let newFormValues = [...listProductLine];
        if (e && (e.target === inputRef.current[index] || e.target === inputSupplierRef.current[index])) {
            newFormValues[index]['visibleDroplist'] = true;
        } else {
            newFormValues[index]['visibleDroplist'] = false;
        }

        setListProductLine(newFormValues)

        const payload = {
            productType: newFormValues[index]['productType'],
            supplierSku: e.target.value,
            // variant_status: "1"
        }

        _fetchDataDroplistSupplierSku(payload)
    }

    const _handleChangeSkuInput = (i, e) => {
        let newFormValues = [...listProductLine];
        newFormValues[i][e.target.name] = e.target.value;

        setListProductLine(newFormValues)

        const payload = {
            productType: newFormValues[i]['productType'],
            [e.target.name]: e.target.value,
            // variant_status: "1"
        }

        _fetchDataDroplist(payload)
    }

    const _handleChangeProductType = (index, value) => {
        let newFormValues = [...listProductLine];
        newFormValues[index]['productType'] = value;
        newFormValues[index]['ffm_variant'] = '';
        newFormValues[index]['sku'] = '';
        newFormValues[index]['supplierSku'] = '';
        newFormValues[index]['variantSupplier'] = '';
        newFormValues[index]['mode'] = '';
        newFormValues[index]['supplier'] = '';
        newFormValues[index]['fulfillmentStatus'] = null;
        newFormValues[index]['add_note_when_mapping'] = null;
        // newFormValues[index]['priority'] = ''

        setListProductLine(newFormValues)
        setValidateProductLine([])
    }

    const _handleChangePriorityInput = (index, e) => {
        let newFormValues = [...listProductLine];
        newFormValues[index][e.target.name] = e.target.value;
        
        setListProductLine(newFormValues)
    }

    const _handleAddNewBox = () => {
        const newPriority = listProductLine.length + 1
        setListProductLine([...listProductLine, { ffm_variant: '', productType: '', visibleDroplist: false, sku: '', supplierSku: '', variantSupplier: '', mode: '', supplier: '', fulfillmentStatus: null, add_note_when_mapping: null, priority: newPriority }])
    }

    const _handleRemoveItemForm = (index) => {
        const newFormArray = [...listProductLine]
        newFormArray.splice(index, 1)
        const newFormArrayLast = newFormArray.map((item, indexMap) => {
            const newItemForm = {
                ...item,
                priority: indexMap + 1
            }

            return newItemForm
        })
        setListProductLine(newFormArrayLast)
        setValidateProductLine([])
    }

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...listProductLine];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        const updatedListLast = updatedList.map((item, indexMap) => {
            const newItemList = {
                ...item,
                priority: indexMap + 1
            }

            return newItemList
        })
        setListProductLine(updatedListLast);
        setValidateProductLine([])
    };

    const validate = () => {
        let flag = true
        const errMappingVariant = []
        const listProductLineCheck = [...listProductLine]
        // listProductLineCheck.splice(-1)
        
        listProductLineCheck.map((item, index) => {
            if(index + 1 !== listProductLineCheck.length && item.ffm_variant === ''){
                errMappingVariant.push(index)
            }
        })
        
        setValidateProductLine(errMappingVariant)

        if (errMappingVariant.length > 0) return flag = false
        return flag
    }

    async function _handleUpdateMappingVariants() {
        if(!validate()){
            return
        }

        // validate()
        setLoading(true)
        setError('')
        try {
            const { match } = props
            const { catalog_id } = match.params

            const mapping_variants = listProductLine.map(item => {
                const { add_note_when_mapping: addNote } = { ...item }
                const ffm_variant = {
                    ffm_variant: item.ffm_variant,
                    priority: item.priority ? item.priority : ''
                }
                if (addNote || addNote === false) ffm_variant.add_note_when_mapping = addNote

                return ffm_variant
            })

            const data = {
                mapping_variants: mapping_variants
            }

            const { success, message: error } = await updateCatalogVariantDetail(catalog_id, data)

            if (!success) {
                setLoading(false)

                return message.error(error)
            }

            setLoading(false)

            const history = getHistory()
            history.push(`/a/mapping-variants`)

            message.success('Update catalog variant successfully!')
        } catch (e) {
            setLoading(false)

            message.error(e.message)
        }
    }


    const handleSave = async () => {
        const hasAddNoteWhenMappingChanged = listProductLine.some((product) => {
            const correspondingProduct = listProductLineBefore.find(
                (item) => item.ffm_variant === product.ffm_variant
            )
            const { add_note_when_mapping: addNote } = { ...correspondingProduct }

            return (addNote || addNote === false) && addNote !== product.add_note_when_mapping
        })

        if (hasAddNoteWhenMappingChanged) {
            Modal.confirm({
                title: 'Are you sure you want to change ON/OFF Add Note When Mapping?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: _handleUpdateMappingVariants,
            })
        } else {
            _handleUpdateMappingVariants()
        }
    }

    const handleCancel = () => {
        const history = getHistory()
        history.push(`/a/mapping-variants`)
    }

    const onChangeProductLabel = () => {
        console.log('onchange product label')
    }

    const _renderVariantSupplier = (options) => {
        const nameVariants = options.map(option => option.name)
        return nameVariants.join('/')
    }

    const handleCheckAddNote = (index, e) => {
        let newFormValues = [...listProductLine];
        newFormValues[index]['add_note_when_mapping'] = e;

        setListProductLine(newFormValues)
    }

    let disableButton = true
    if (JSON.stringify(listProductLineBefore) !== JSON.stringify(listProductLine)) {
        disableButton = false
    }

    const { match } = props
    const { catalog_id } = match.params

    return (
        <div className="MappingVariantDetailContainer">
            <div className="BackWrapper">
                <Link to='/a/mapping-variants' className="pt-2 pb-2 d-block"><i className='fa fa-chevron-left mr-2' />Mapping Catalog - Product Line</Link>
            </div>

            <div className="TitlePage d-flex justify-content-between align-items-center">
                <div className='leftBox d-flex justify-content-between align-items-center'>
                    <h1>{catalogVariant.sku ? catalogVariant.sku : ''}</h1>
                    <span className={`mapping-status ml-5 ${catalogVariant.mapping_status}`}>
                        {catalogVariant.mapping_status ? catalogVariant.mapping_status : ''}
                    </span>
                </div>

                <div className='action'>
                    <Button className='btn-secondary ml-3' onClick={handleCancel}>
                        Cancel
                    </Button>

                    {permissionsData.includes('ffm_catalog_variant_update') &&
                        <Button className='btn-primary ml-3' onClick={handleSave} disabled={disableButton}>
                            Save
                        </Button>
                    }

                </div>
            </div>

            <div className="card MappingVariantDetail">
                <div className="card-body">
                    <div className="FormMappingVariant">
                        <div className='MappingVariantField'>
                            <div className="itemField mb-3 d-flex align-items-center">
                                <label className="itemLabel col-form-label font-weight-bold" style={{ width: 200 }}>Product label</label>
                                <div className="itemValue">
                                    <Radio.Group onChange={onChangeProductLabel} value={catalogVariant.label} disabled>
                                        <Radio value='product'>Product</Radio>
                                        <Radio value='branding'>Branding</Radio>
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className="itemField mb-3 d-flex align-items-center">
                                <label className="itemLabel col-form-label font-weight-bold" style={{ width: 200 }}>Product Title</label>
                                <div className="itemValue">
                                    <span className="itemValueInput">{catalogVariant.product_title || ''}</span>
                                </div>
                            </div>

                            <div className="itemField mb-3 d-flex align-items-center">
                                <label className="itemLabel col-form-label font-weight-bold" style={{ width: 200 }}>SKU Variant Merchize</label>
                                <div className="itemValue">
                                    <span className="itemValueInput">{catalogVariant.sku || ''}</span>
                                </div>
                            </div>

                            <div className="itemField mb-3 d-flex align-items-center">
                                <label className="itemLabel col-form-label font-weight-bold" style={{ width: 200 }}>Variant Merchize</label>
                                <div className="itemValue">
                                    <span className="itemValueInput">{catalogVariant.options && catalogVariant.options.length > 0 ? catalogVariant.options.join('/') : ''}</span>
                                </div>
                            </div>

                            <div className="itemField mb-3 d-flex align-items-center">
                                <label className="itemLabel col-form-label font-weight-bold" style={{ width: 200 }}>Catalog status</label>
                                <div className="itemValue">
                                    <span className="itemValueInput catalogStatus">{catalogVariant.catalog_status || ''}</span>
                                </div>
                            </div>

                            <div className="itemField mb-3">
                                <h5>PRODUCT LINE</h5>
                                <div className='table-container'>
                                    {/* <table>
                                        <thead>
                                            <tr>
                                                <th className="productType" style={{ width: 250 }}>Product Type</th>
                                                <th className="sku" style={{ width: 350 }}>SKU</th>
                                                <th className="supplierSKU" style={{ width: 350 }}>Supplier SKU</th>
                                                <th className="variantSupplier">Variant Supplier</th>
                                                <th className="variantSupplier">Mode</th>
                                                <th className="supplier">Supplier</th>
                                                <th className="FulfillmentStatus">Fulfillment Status</th>
                                                <th className="add_note_when_mapping" style={{ minWidth: '154px' }}>Add Note When Mapping</th>
                                                 <th className="Priority">Priority</th>
                                                <th className="removeLine"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {listProductLine.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: 250 }}>
                                                        <div className=''>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder=""
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: 200 }}
                                                                onChange={value => _handleChangeProductType(index, value)}
                                                                options={optionsProductType}
                                                                value={item.productType || undefined}
                                                            />
                                                        </div>
                                                    </td>

                                                    <td style={{ width: 350 }}>
                                                        <div className='searchAdvance'>
                                                            <div className='searchInput'>
                                                                <input
                                                                    ref={el => inputRef.current[index] = el}
                                                                    name="sku"
                                                                    className="ant-input"
                                                                    onChange={e => _handleChangeSkuInput(index, e)}
                                                                    // onFocus={() => _onFocusInput(index)}
                                                                    onClick={e => _onClickInput(index, e)}
                                                                    value={item.sku || ''}
                                                                    style={{ width: 300 }}
                                                                />

                                                                {
                                                                    item.visibleDroplist ? <span className="std-select-search">
                                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                                                    </span> : <span className="std-select-arrow">
                                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                                                                    </span>
                                                                }
                                                            </div>

                                                            <div ref={el => dropdownRef.current[index] = el} className={`searchDroplist ${item.visibleDroplist ? 'show' : 'hide'}`}>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: 335 }}>SKU</th>
                                                                            <th style={{ width: 335 }}>Supplier SKU</th>
                                                                            <th>Variant Supplier</th>
                                                                            <th>Mode</th>
                                                                            <th>Supplier</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dataDroplist.length > 0 &&
                                                                            dataDroplist.map((item, dropListIndex) => (
                                                                                <tr key={dropListIndex} onClick={() => _onSelectOption(index, item)}>
                                                                                    <td style={{ width: 335 }}>{item.sku || ''}</td>
                                                                                    <td style={{ width: 335 }}>{item.partner_sku || ''}</td>
                                                                                    <td>{_renderVariantSupplier(item.options)}</td>
                                                                                    <td>{item.product.store_modes ? item.product.store_modes.join(', ') : ''}</td>
                                                                                    <td>{item.supplier ? item.supplier.name : ''}</td>
                                                                                </tr>
                                                                            ))
                                                                        }

                                                                        {
                                                                            dataDroplist.length === 0 &&
                                                                            <tr style={{ textAlign: 'center' }}>No data</tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td style={{ width: 350 }}>
                                                        <div className='searchAdvance'>
                                                            <div className='searchInput'>
                                                                <input
                                                                    ref={el => inputSupplierRef.current[index] = el}
                                                                    name="supplierSku"
                                                                    className="ant-input"
                                                                    onChange={e => _handleChangeSkuInput(index, e)}
                                                                    // onFocus={() => _onFocusInput(index)}
                                                                    onClick={e => _onClickInputSupplierSku(index, e)}
                                                                    value={item.supplierSku || ''}
                                                                    style={{ width: 330 }}
                                                                />

                                                                {
                                                                    item.visibleDroplist ? <span className="std-select-search">
                                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                                                    </span> : <span className="std-select-arrow">
                                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <span>{item.variantSupplier}</span>
                                                    </td>

                                                    <td>
                                                        <span>{item.mode}</span>
                                                    </td>

                                                    <td>
                                                        <span>{item.supplier}</span>
                                                    </td>

                                                    <td>
                                                        <span>
                                                            {
                                                                item.fulfillmentStatus !== null &&
                                                                <Switch checked={!item.fulfillmentStatus} style={{ cursor: 'default' }} />
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {(item.add_note_when_mapping || item.add_note_when_mapping === false) &&
                                                                <Switch checked={item.add_note_when_mapping} onChange={(e) => handleCheckAddNote(index, e)} />
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='priorityInput'>
                                                            <input id='priority' className='form-control' type='number' name='priority' value={item.priority || ""}
                                                            onChange={e => _handleChangePriorityInput(index, e)}/>
                                                        </div>
                                                    </td>
                                                    
                                                    <td style={{ width: 50 }}>
                                                        {
                                                            permissionsData.includes('ffm_catalog_variant_update') && listProductLine.length > 1 ? <span className="remove-item cursor-pointer" onClick={() => _handleRemoveItemForm(index)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <circle cx="12" cy="12" r="8.4" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                    <path d="M9 14.9995L15 8.99951" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                    <path d="M15 15L9 9" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                </svg>
                                                            </span> : ''
                                                        }

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> */}

                                    <DragDropContext onDragEnd={handleDrop}>
                                        <Droppable droppableId="list-container">
                                        {(provided) => (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="productType" style={{ width: 250 }}>Product Type</th>
                                                        <th className="sku" style={{ width: 350 }}>SKU</th>
                                                        <th className="supplierSKU" style={{ width: 350 }}>Supplier SKU</th>
                                                        <th className="variantSupplier">Variant Supplier</th>
                                                        <th className="variantSupplier">Mode</th>
                                                        <th className="supplier">Supplier</th>
                                                        <th className="FulfillmentStatus">Fulfillment Status</th>
                                                        <th className="add_note_when_mapping" style={{ minWidth: '154px' }}>Add Note When Mapping</th>
                                                        <th className="Priority">Priority</th>
                                                        <th className="removeLine"></th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody
                                                className="list-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                >
                                                {listProductLine.map((item, index) => (
                                                    <Draggable 
                                                    key={index} 
                                                    draggableId={`product-line-${index}`} 
                                                    index={index}
                                                    // isDragDisabled={index === itemList.length - 1 ? true : false}
                                                    >
                                                    {(provided) => (
                                                        <tr
                                                        className={`item-container ${validateProductLine.includes(index) ? 'error': ''}`}
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                        >
                                                            <td style={{ width: 250 }}>
                                                                <div className=''>
                                                                    <Select
                                                                        showSearch
                                                                        allowClear
                                                                        placeholder=""
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: 200 }}
                                                                        onChange={value => _handleChangeProductType(index, value)}
                                                                        options={optionsProductType}
                                                                        value={item.productType || undefined}
                                                                    />
                                                                </div>
                                                            </td>

                                                            <td style={{ width: 350 }}>
                                                                <div className='searchAdvance'>
                                                                    <div className='searchInput'>
                                                                        <input
                                                                            ref={el => inputRef.current[index] = el}
                                                                            name="sku"
                                                                            className="ant-input"
                                                                            onChange={e => _handleChangeSkuInput(index, e)}
                                                                            // onFocus={() => _onFocusInput(index)}
                                                                            onClick={e => _onClickInput(index, e)}
                                                                            value={item.sku || ''}
                                                                            style={{ width: 300 }}
                                                                        />

                                                                        {
                                                                            item.visibleDroplist ? <span className="std-select-search">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                                                            </span> : <span className="std-select-arrow">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                                                                            </span>
                                                                        }
                                                                    </div>

                                                                    <div ref={el => dropdownRef.current[index] = el} className={`searchDroplist ${item.visibleDroplist ? 'show' : 'hide'}`}>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: 335 }}>SKU</th>
                                                                                    <th style={{ width: 335 }}>Supplier SKU</th>
                                                                                    <th>Variant Supplier</th>
                                                                                    <th>Status</th>
                                                                                    <th>Mode</th>
                                                                                    <th>Supplier</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    dataDroplist.length > 0 &&
                                                                                    dataDroplist.map((item, dropListIndex) => (
                                                                                        <tr key={dropListIndex} onClick={() => _onSelectOption(index, item)}>
                                                                                            <td style={{ width: 335 }}>{item.sku || ''}</td>
                                                                                            <td style={{ width: 335 }}>{item.partner_sku || ''}</td>
                                                                                            <td>{_renderVariantSupplier(item.options)}</td>
                                                                                            <td><Switch checked={!item.is_disabled} style={{ cursor: 'default' }} /></td>
                                                                                            <td>{item.product.store_modes ? item.product.store_modes.join(', ') : ''}</td>
                                                                                            <td>{item.supplier ? item.supplier.name : ''}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    dataDroplist.length === 0 &&
                                                                                    <tr style={{ textAlign: 'center' }}>No data</tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td style={{ width: 350 }}>
                                                                <div className='searchAdvance'>
                                                                    <div className='searchInput'>
                                                                        <input
                                                                            ref={el => inputSupplierRef.current[index] = el}
                                                                            name="supplierSku"
                                                                            className="ant-input"
                                                                            onChange={e => _handleChangeSkuInput(index, e)}
                                                                            // onFocus={() => _onFocusInput(index)}
                                                                            onClick={e => _onClickInputSupplierSku(index, e)}
                                                                            value={item.supplierSku || ''}
                                                                            style={{ width: 330 }}
                                                                        />

                                                                        {
                                                                            item.visibleDroplist ? <span className="std-select-search">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                                                            </span> : <span className="std-select-arrow">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <span>{item.variantSupplier}</span>
                                                            </td>

                                                            <td>
                                                                <span>{item.mode}</span>
                                                            </td>

                                                            <td>
                                                                <span>{item.supplier}</span>
                                                            </td>

                                                            <td>
                                                                <span>
                                                                    {
                                                                        item.fulfillmentStatus !== null &&
                                                                        <Switch checked={!item.fulfillmentStatus} style={{ cursor: 'default' }} />
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {(item.add_note_when_mapping || item.add_note_when_mapping === false) &&
                                                                        <Switch checked={item.add_note_when_mapping} onChange={(e) => handleCheckAddNote(index, e)} />
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className='priorityInput'>
                                                                    {/* <input id='priority' className='form-control' type='number' name='priority' value={item.priority || ""}
                                                                    onChange={e => _handleChangePriorityInput(index, e)}/> */}
                                                                    {item.priority}
                                                                    {/* {index + 1} */}
                                                                </div>
                                                            </td>
                                                            
                                                            <td style={{ width: 50 }}>
                                                                {
                                                                    permissionsData.includes('ffm_catalog_variant_update') && listProductLine.length > 1 ? <span className="remove-item cursor-pointer" onClick={() => _handleRemoveItemForm(index)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <circle cx="12" cy="12" r="8.4" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                            <path d="M9 14.9995L15 8.99951" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                            <path d="M15 15L9 9" stroke="black" strokeOpacity="0.69" strokeWidth="1.2" />
                                                                        </svg>
                                                                    </span> : ''
                                                                }

                                                            </td>
                                                        </tr>
                                                    )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                </tbody>
                                            </table>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                {permissionsData.includes('ffm_catalog_variant_update') &&
                                    <Button type="text" onClick={_handleAddNewBox} className="add-box">+ Add box</Button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card SettingBoxesHistory mt-3">
                <div className="card-body">
                    <MappingVariantHistory catalogId={catalog_id} />
                </div>
            </div>
        </div>
    )
}

export default MappingVariantDetail