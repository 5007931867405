import React from 'react'
import { Input, DatePicker, Tooltip, Select } from 'antd'
import moment from 'moment'
import CutoffSettingRow from './CutoffSettingRow'
import { Draggable } from "react-beautiful-dnd"

function SettingHolidayDetailRow(props) {
    const { RangePicker } = DatePicker
    const {
        item,
        index,
        preCutoff,
        addSetting,
        errorValidate,
        currentData = {},
        removeSetting,
        handleChangeSetting,
        onRemoveSettingCutoff,
    } = { ...props }
    const { start_time, end_time, name, cutoff_settings = [], shipping_date = '', cutoff_tags = [], time_zone = null } = { ...item }
    const holidayDate = start_time && end_time ? [moment(start_time).utcOffset(0).startOf('day'), moment(end_time).utcOffset(0).endOf('day')] : null
    const shippingDate = shipping_date ? moment(shipping_date).utcOffset(0) : null

    const CUTOFF_DATE_FORMAT = 'DD/MM/YYYY HH:mm'
    const HOLIDAY_DATE_FORMAT = 'DD/MM/YYYY'

    const getItemStyle = (isDragging, draggableStyle) => ({
        position: 'relative',
        ...draggableStyle,
    })

    const disabledDate = current => {
        const today = moment().utc()
        const disableSameOrBeforeToday = moment(current).isSameOrBefore(today)
        return disableSameOrBeforeToday
    }

    const disabledCutoffDate = (current, cutoffTimeOld) => {
        let today = moment().utc()
        let disableBeforeToday = moment(current).isBefore(today.startOf('day'))
        let disableSameOrAfterStart = (!!start_time && !!end_time) && moment(current).endOf('day').isSameOrAfter(moment(holidayDate[0]))
        let disableBeforeCutoffDateOld = !!cutoffTimeOld && moment(current).isBefore(cutoffTimeOld)
        return current && !!start_time && !!end_time ? (disableBeforeToday || disableSameOrAfterStart || disableBeforeCutoffDateOld) : disableBeforeToday
    }
    const today = moment().utc().add(time_zone, 'hours').toISOString()

    const holidayCutoff = cutoff_settings.some(setting => {
        const { isSave = false } = Object.assign({}, setting)
        const cutoffDateSetting = moment(setting.cutoff_time).subtract(Number(preCutoff), "days")
        const isCutoff = isSave && moment(today).isBetween(cutoffDateSetting, setting.cutoff_time)
        return isCutoff
    })
    const expiredHolidayDate = !!start_time && !!end_time && moment(today).isAfter(holidayDate[1])
    const isNearDateCutoff = cutoff_settings.some(setting => {
        const { isSave = false, cutoff_time } = Object.assign({}, setting)
        return isSave && !!cutoff_time && moment(today).isAfter(cutoff_time)
    })

    const error = errorValidate && errorValidate.length > 0 && errorValidate.filter(i => i.row === index)
    const nameError = error && error.length > 0 && error[0].name
    const holidayDateError = error && error.length > 0 && error[0].holiday_date
    const cutoffDateError = error && error.length > 0 && error[0].cutoff_time

    return (
        <React.Fragment>
            {
                cutoff_settings.map((cutoffItem, i) => {
                    const { isUsing = 'no' } = Object.assign({}, cutoffItem)
                    const settingUsing = cutoff_settings.filter(o => !!o.isUsing && o.isUsing === 'yes') || []

                    return (
                        <Draggable key={`setting-${item._id ? item._id : item.idx}-${cutoffItem.priority}`} draggableId={`cutoff-${item._id ? item._id : item.idx}-${cutoffItem.priority}`} index={i} className="position-relative">
                            {(provided, snapshot) => (
                                <tr
                                    className='setting-cutoff'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    {
                                        i === 0 ?
                                            <td className='holiday-name'>
                                                <div>
                                                    <Input
                                                        allowClear
                                                        onChange={(e) => handleChangeSetting(index, 'name', e.target.value)}
                                                        value={name}
                                                        placeholder='Input Holiday Name'
                                                        disabled={holidayCutoff || expiredHolidayDate}
                                                        status="error"
                                                    />
                                                </div>
                                                {nameError &&
                                                    <small className='text-danger'>{nameError}</small>
                                                }
                                            </td> : <td className='border-0'></td>
                                    }
                                    {[0, 1, 2].includes(i) ?
                                        <td className='holiday-date position-relative border-0'>
                                            {
                                                i === 1 &&
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className="shipping-date-title font-weight-normal">Shipping date</div>
                                                    <DatePicker
                                                        allowClear
                                                        value={shippingDate}
                                                        onChange={(value, dateString) => handleChangeSetting(index, 'shipping_date', value)}
                                                        placeholder='Choose shipping date'
                                                        format={CUTOFF_DATE_FORMAT}
                                                        showTime={{
                                                            hideDisabledOptions: true,
                                                            format: 'HH:mm',
                                                            defaultValue: moment('23:59', 'HH:mm'),
                                                        }}
                                                        style={{ width: '60%' }}
                                                        disabledDate={(current) => {
                                                            if (!cutoff_settings.length) return false
                                                            let latestCutoffTime = moment.max(cutoff_settings.map(c => moment(c.cutoff_time)))

                                                            return current && (current.isBefore(moment(), 'day') || current.isBefore(latestCutoffTime))
                                                        }}
                                                        disabled={holidayCutoff || expiredHolidayDate}
                                                    />
                                                </div>
                                            }
                                            {
                                                i === 0 && <div>
                                                    <RangePicker
                                                        allowClear
                                                        onChange={(value, dateString) => handleChangeSetting(index, 'holidayDate', value)}
                                                        value={holidayDate}
                                                        placeholder={['Holiday start', 'Holiday end']}
                                                        style={{ width: '100%' }}
                                                        format={HOLIDAY_DATE_FORMAT}
                                                        disabledDate={disabledDate}
                                                        disabled={holidayCutoff || expiredHolidayDate}
                                                    />
                                                </div>
                                            }
                                            {
                                                i === 2 &&
                                                <div className='cutoff-tag border-0 d-flex align-items-center justify-content-between'>
                                                    <div className="cutoff-tag-date-title font-weight-normal">Cutoff tag</div>
                                                    <Select
                                                        value={cutoff_tags}
                                                        style={{ width: '60%' }}
                                                        onChange={(value, dateString) => handleChangeSetting(index, 'cutoff_tags', value)}
                                                        placeholder="Choose cutoff tag"
                                                        allowClear
                                                        options={[
                                                            {
                                                                label: 'Cutoff noel',
                                                                value: 'cutoff_noel',
                                                            },
                                                            {
                                                                label: 'Cutoff new year',
                                                                value: 'cutoff_new_year',
                                                            },
                                                        ]}
                                                        disabled={holidayCutoff || expiredHolidayDate}
                                                    />
                                                </div>
                                            }
                                            {holidayDateError &&
                                                <small className='text-danger'>{holidayDateError}</small>
                                            }
                                        </td> : <td className='border-0'></td>
                                    }
                                    {
                                        isUsing === 'yes' ?
                                            <CutoffSettingRow
                                                key={`${cutoffItem.priority}_cutoff_setting`}
                                                total={settingUsing.length}
                                                indexSetting={i}
                                                index={index}
                                                addSetting={addSetting}
                                                preCutoff={preCutoff}
                                                cutoffItem={cutoffItem}
                                                cutoffDateError={cutoffDateError}
                                                handleChangeSetting={handleChangeSetting}
                                                disabledCutoffDate={disabledCutoffDate}
                                                holidayCutoff={holidayCutoff}
                                                onRemoveSettingCutoff={onRemoveSettingCutoff}
                                                expiredHolidayDate={expiredHolidayDate}
                                                timeZone={time_zone}
                                                currentCutoffSetting={(!!currentData.cutoff_settings && !!currentData.cutoff_settings[i]) ? currentData.cutoff_settings[i] : {}}
                                            /> :
                                            <>
                                                <td className='border-left border-top-0'></td>
                                                <td className='border-top-0'></td>
                                                <td className='border-top-0'></td>
                                            </>
                                    }
                                    {i === 0 &&
                                        <td className='actions text-right' rowSpan={cutoff_settings.length}>
                                            {
                                                // (!disableHolidayDate && !holidayCutoff) &&
                                                !isNearDateCutoff && !holidayCutoff && !expiredHolidayDate &&
                                                <Tooltip title='Delete' className="ti-close text-danger ml-2 cursor-pointer" onClick={() => removeSetting(index)} />
                                            }
                                        </td>
                                    }
                                </tr>
                            )}
                        </Draggable>
                    )
                })
            }
        </React.Fragment>
    )
}

export default SettingHolidayDetailRow