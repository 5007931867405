import React from 'react'
import ReportCutoffTime from './ReportCutoffTime'

function CutoffReport(props) {
    return (
        <div className="ShipmentReports">
            <div className="mb-5">
                <ReportCutoffTime/>
            </div>
        </div>
    )
}

export default CutoffReport
