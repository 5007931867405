import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Clipboard from "react-clipboard.js"
import { listStoreModes } from "../../../../statics/storeModes"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import imageDefault from "../../../../helpers/common/imageDefault"
import GroupArtworkItem from "./GroupArtworkItem"
import { Tooltip } from 'antd'

class ProductSource extends Component {
    state = {
        copyText: "Copy image url",
        showAll: false,
    }

    _renderStoreMode = (storeModes) => {
        return listStoreModes.find(mode => mode.value === storeModes)
    }

    _handleCopyAddressSuccess = () => {
        this.setState({
            copyText: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyText: "Copy image url",
            })
        }, 2000)
    }

    _onChangeShowAll = () => {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    _onClickSelectionText = (e, key, value) => {
        e.preventDefault()
        const { _handUpdateState, filter, attributes } = { ...this.props }
        const keyLowerCase = key.toLocaleLowerCase()
        if (['color', 'colors', 'size', 'sizes'].includes(keyLowerCase)) {
            const result = this._findIdByValue(attributes, value)
            _handUpdateState((['size', 'sizes'].includes(keyLowerCase) ? 'selectionSize' : 'selectionColor'), result)
            return
        }
        const selectionText = window.getSelection().toString().trim()
        _handUpdateState('filter', {
            ...filter,
            term: !!selectionText ? selectionText : value,
            page: 1,
        })
        return
    }

    _findIdByValue = (data, text) => {
        const textLocaleLowerCase = text.toLocaleLowerCase()
        for (const item of data) {
            const found = item.values.find(value => !!value.name && !!value.value &&
                [value.value.toLocaleLowerCase(), value.name.toLocaleLowerCase()].includes(textLocaleLowerCase)
            )
            if (found) {
                return found._id
            }
        }
        return null
    }

    render() {
        const { copyText, showAll } = this.state
        const { orderItem, storeMode, artworksMore, artworks, error, attributes, selectedProduct } = this.props
        const { product, variant, price, quantity, currency } = orderItem
        const { options = [] } = { ...variant }
        const titleValues = !!variant.title && variant.title.includes(' / ') ? variant.title.split(' / ') : [variant.title]
        const updatedVariantOptions = options.map((option, index) => ({
            ...option,
            value: titleValues[index],
            disabled: ['color', 'colors', 'size', 'sizes'].includes(option.key.toLocaleLowerCase()) ? !selectedProduct : !!selectedProduct
        }))
        const variantTitleTooltip = {
            product: 'Click here to search products with variant title',
            title: 'Click here to search products with variant title',
            color: 'Click here to filter by Color',
            colors: 'Click here to filter by Color',
            size: 'Click here to filter by Size',
            sizes: 'Click here to filter by Size',
        }
        const renderStoreMode = storeMode !== undefined && this._renderStoreMode(storeMode)

        return (
            <div className="ProductSource">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="position-relative ThumbnailWrapper">
                            <img
                                src={product.preview}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = imageDefault
                                }}
                                alt="Product thumbnail"
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        {/* <Clipboard
                            component="div"
                            data-clipboard-text={product.preview}
                            className="CopyTitleMap cursor-pointer d-inline-block"
                            onSuccess={this._handleCopyAddressSuccess}
                        >
                            <div className="Tooltip3 Right">
                                <i className="far fa-copy"/>

                                <div className="TooltipContent Clipboard">
                                    <div className="ContentInner text-center">
                                        <small className="text-monospace">{copyText}</small>
                                    </div>
                                </div>
                            </div>
                        </Clipboard> */}
                        <a href={product.url} target="_blank" rel="noopener noreferrer"
                            className="mb-2 d-block text-break">{product.title}</a>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Price:</span>
                            <span className='text-break'>{formatCurrency(currency, price)}</span>
                        </div>

                        <div className="d-flex mb-1">
                            <span className="Label font-weight-500">Variant:</span>
                            <span className='variantTitle'>
                                {updatedVariantOptions.map((i, idx) => {
                                    const keyLowerCase = i.key.toLocaleLowerCase()
                                    const hasIdInAttribute = ['color', 'colors', 'size', 'sizes'].includes(keyLowerCase) ? this._findIdByValue(attributes, i.value) : true
                                    return <span key={`${i.key}_${idx}`}>
                                        {(idx === 0 || ['color', 'colors', 'size', 'sizes',].includes(keyLowerCase)) && !i.disabled && !!hasIdInAttribute ?
                                            <Tooltip title={['color', 'colors', 'size', 'sizes'].includes(keyLowerCase) ? variantTitleTooltip[keyLowerCase] : variantTitleTooltip['product']}>
                                                <span
                                                    className={`cursor-pointer ${!i.disabled && 'text-primary textHover'}`}
                                                    onClick={(e) => this._onClickSelectionText(e, i.key, i.value)}
                                                >
                                                    {i.value}
                                                </span>
                                            </Tooltip> : <span>{i.value}</span>
                                        }
                                        {updatedVariantOptions.length > 1 && idx !== (updatedVariantOptions.length - 1) &&
                                            <span key={i.key} className='mx-1'>/</span>
                                        }
                                    </span>
                                })}
                            </span>
                        </div>

                        <div className="Quantity d-flex align-items-center">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span className="Number">{quantity}</span>
                        </div>

                        <div className="storeMode d-flex align-items-center">
                            <span className="Label font-weight-500">Store mode:</span>
                            <span className="Number">{renderStoreMode.name}</span>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            !!artworks.length && <div className="listArtwork">
                                {
                                    artworks.map((item, index) => (
                                        <GroupArtworkItem
                                            key={`first_${index}`}
                                            artwork={item}
                                        />
                                    ))
                                }
                            </div>
                        }

                        {
                            !showAll && !!artworksMore && artworksMore.length > 0 ?
                                <div className="ShowAll" onClick={this._onChangeShowAll}>
                                    View more
                                </div> :
                                <>
                                    <div className="listArtwork">
                                        {
                                            showAll && !!artworksMore && artworksMore.length > 0 && artworksMore.map((item, index) => (
                                                <GroupArtworkItem
                                                    key={`first_${index}`}
                                                    artwork={item}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        !!artworksMore && artworksMore.length > 0 &&
                                        <div className="ShowAll" onClick={this._onChangeShowAll}>
                                            Hide
                                        </div>
                                    }
                                </>
                        }

                        {
                            !!error &&
                            <div className="text-danger">{error}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

ProductSource.propTypes = {
    orderItem: PropTypes.object.isRequired,
    orderItemId: PropTypes.string,
    storeMode: PropTypes.string,
    orderId: PropTypes.string
}

export default ProductSource
