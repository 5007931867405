import React, { useState, useMemo } from 'react'
import { addFastProductionTag } from '../../../../../services/api/OrdersServices'
import { Modal, Progress, Checkbox } from "antd"
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const AddTagFastproduction = ({ fulfilledPackages = [], isOpen = false, onCancel, onFetchOrder }) => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [listSuccess, setListSuccess] = useState([])
    const [listError, setListError] = useState([])
    const [listSelected, setListSelected] = useState(fulfilledPackages.map(i => ({ ...i, checked: true })))
    const [listProcessing, setListProcessing] = useState(fulfilledPackages.filter(item => listSelected.includes(item._id)))

    const listPackageChecked = useMemo(() => listSelected.filter(i => i.checked) || [], [listSelected])

    const _onAddTag = async (id) => {
        try {
            const response = await addFastProductionTag(id)
            const { success, message } = response
            if (!success) {
                return { success: success, message: message }
            }
            return { success: success }
        } catch (e) {
            return { success: false, message: e.message }
        }
    }

    const _handleOk = async () => {
        setButtonLoading(true)
        let processed = []

        for (const item of listPackageChecked) {
            const response = await _onAddTag(item._id)
            const { success, message } = response

            if (success !== true) {
                processed = [...processed, { ...item, success: success, error: message }]
            }
            else {
                processed = [...processed, { ...item, success: success }]
            }
        }

        let newListSuccess = []
        let newListError = []

        processed.map(process => {
            if (process.success === true) {
                return newListSuccess.push(process)
            }

            if (process.error.length > 0) {
                return newListError.push(process)
            }
        })
        setListSuccess(newListSuccess)
        setListError(newListError)
        setListProcessing(processed)
        setButtonLoading(false)
    }

    const onChangeChecked = (e, id) => {
        setListSelected(preSelected => {
            return preSelected.map(selected => {
                if(selected._id === id){
                    return {...selected, checked: e.target.checked}
                }
            
                return selected
            })
        })
    }

    const handleCancel = () => {
        onCancel()
        if(!!listError.length || !!listSuccess.length) onFetchOrder()
    }

    return (
        <Modal
            className="AddFastProductionModalTag"
            visible={isOpen}
            title="Add Fast Production tag for packages"
            onOk={_handleOk}
            okText={"Add Tag"}
            onCancel={handleCancel}
            confirmLoading={buttonLoading}
        >
            <h3>Select package to add tag:</h3>
            <div 
                className="list-package-checkbox mb-3" 
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)'
                }}
            >
                {
                    listSelected.map(item => {
                        return (
                            <Checkbox 
                                key={item._id} 
                                checked={item.checked} 
                                className='ml-0' 
                                onChange={e => onChangeChecked(e, item._id)}
                            >{item.name}</Checkbox>
                        )
                    })
                }
            </div>
            {
                (listSuccess.length > 0 || listError.length > 0) &&
                <div className="Results">
                    <div className="ResultSuccess">
                        Success: <span>{listSuccess.length}</span> items
                    </div>
                    <div className="ResultError">
                        Error: <span>{listError.length}</span> items
                    </div>
                </div>
            }
            <div className="ListProcessing">
                <ul>
                    {!!listProcessing && listProcessing.length > 0 &&
                        listProcessing.map(item => (
                            <li key={item._id}>
                                <span>{item.name}</span>
                                {
                                    item.success === false && !!item.error &&
                                    <span className="Error"><ExclamationCircleOutlined /> {item.error}</span>
                                }
                                {
                                    item.success === true &&
                                    <span className="Success"><CheckCircleOutlined /> Added Fast Production tag</span>
                                }
                                <Progress
                                    percent={100}
                                    size="small"
                                    status={`${(item.success === true) ? 'success' : ((item.success === false) ? 'exception' : 'active')}`}
                                    showInfo={false}
                                />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default AddTagFastproduction