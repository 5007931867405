import React from 'react'
import SettingHolidayDetailHeader from './SettingHolidayDetailTable/SettingHolidayDetailHeader'
import SettingHolidayDetailRow from './SettingHolidayDetailTable/SettingHolidayDetailRow'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
function SettingHolidayDetailTable(props) {
    const {
        error,
        sortType,
        preCutoff,
        handleSort,
        addSetting,
        errorValidate,
        removeSetting,
        currentData = [],
        handleChangeSetting,
        listSettingDetail = [],
        onRemoveSettingCutoff,
        setListSettingDetail,
    } = { ...props }

    const handleDragEnd = (droppedItem) => {
        if (!droppedItem.destination) return
        const { droppableId = '' } = Object.assign({}, droppedItem.destination)
        const newCutoff = listSettingDetail.map((detail, index) => {
            if (`droppable-${index}` === droppableId) {
                const { cutoff_settings = [] } = Object.assign({}, detail)
                const nextSetting = cutoff_settings.find(u => !!u.priority && u.priority === droppedItem.destination.index + 1) || {}
                const currentSetting = cutoff_settings.find(u => !!u.priority && u.priority === droppedItem.source.index + 1) || {}
                const isDrag = !!nextSetting.isUsing && nextSetting.isUsing === 'no'
                const isDrag2 = !!currentSetting.isUsing && currentSetting.isUsing === 'no'
                if (isDrag || isDrag2) return detail

                const currentIndex = droppedItem.destination.index + 1
                const preIndex = droppedItem.source.index + 1
                var cutoffSettings = [...cutoff_settings]
                const [reorderedItem] = cutoffSettings.splice(droppedItem.source.index, 1)
                cutoffSettings.splice(droppedItem.destination.index, 0, reorderedItem)

                const cutoffSetting = cutoffSettings.map(i => {
                    if (!i || !i.priority) return i
                    if (preIndex < currentIndex) {
                        if (i.priority === preIndex) {
                            return {
                                ...i,
                                priority: currentIndex
                            }
                        }

                        if (i.priority <= currentIndex && i.priority > preIndex) {
                            return {
                                ...i,
                                priority: i.priority - 1
                            }
                        }

                        return i
                    } else {
                        if (i.priority === preIndex) {
                            return {
                                ...i,
                                priority: currentIndex
                            }
                        }

                        if (i.priority <= preIndex && i.priority >= currentIndex) {
                            return {
                                ...i,
                                priority: i.priority + 1
                            }
                        }

                        return i
                    }
                }).filter(Boolean)

                const newCutoffSetting = cutoffSetting.map((t, o) => ({...t, priority: o + 1}))

                return {
                    ...detail,
                    cutoff_settings: newCutoffSetting
                }
            }

            return detail
        })
        setListSettingDetail(newCutoff)
    }

    const newListSettingDetail = listSettingDetail.sort((a, b) => a.priority - b.priority)

    return (
        <div className='SettingHolidayDetailTable pt-3'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <table className='table'>
                    <SettingHolidayDetailHeader handleSort={handleSort} sortType={sortType} newListSettingDetail={newListSettingDetail} />
                    {/* <tbody className='SettingHolidayDetailRow'> */}
                    {!!newListSettingDetail && newListSettingDetail.length > 0 && newListSettingDetail.map((item, i) => {
                        return (
                            <Droppable key={item._id ? item._id : item.idx} droppableId={`droppable-${i}`}>
                                {(provided) => (
                                    <tbody className='SettingHolidayDetailRow' ref={provided.innerRef} {...provided.droppableProps}>
                                        <SettingHolidayDetailRow
                                            key={item._id ? item._id : item.idx}
                                            index={i}
                                            item={item}
                                            addSetting={addSetting}
                                            removeSetting={removeSetting}
                                            handleChangeSetting={handleChangeSetting}
                                            errorValidate={errorValidate}
                                            preCutoff={preCutoff}
                                            currentData={currentData[i]}
                                            onRemoveSettingCutoff={onRemoveSettingCutoff}
                                        />
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        )
                    })}
                    {/* </tbody> */}
                </table>
            </DragDropContext>
            {error.message &&
                <div>
                    <label className='text-danger'>{error.message}</label>
                </div>
            }
            <button className='btn btn-link' onClick={() => addSetting()}>+ Add Holiday</button>
        </div>
    )
}

export default SettingHolidayDetailTable