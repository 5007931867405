import React, {useEffect, useState} from 'react'
import {Spin, Tooltip} from "antd"
import {getCutoffReport} from "../../../../services/api/SuppliersServices"
import ReportCutoffTimeRow from './ReportCutoffTimeRow'
import { InfoCircleOutlined } from '@ant-design/icons';

function ReportCutoffTime(props) {
    const [reportData, setStaticsCutoffTime] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    async function _fetchReportCutoff() {
        try {
            setLoading(true)
            setError('')
            
            const response = await getCutoffReport()
            const {success, data, message} = response

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)
            setStaticsCutoffTime(data.data)

        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchReportCutoff()
    }, [])

    return (
        <div className="ProductionReports">
            <div className="ReportHeading d-flex justify-content-between" id="Filter">
                {/* <h3>Production Time Report{currentTab && ' by Store'}</h3> */}
            </div>
            <div className="text-danger Error" id="Error">{error}</div>
            <Spin spinning={loading} tip="Getting statics..."/>

            <table className="table table-borderless ProductionTimeTable">
                <thead>
                    <tr>
                        <th className="col-supplier">Supplier</th>
                        <th className="col-date">Holiday Date</th>
                        <th className="col-total">
                            Total
                            <Tooltip title="The total number of orders (packages) that need to be cut off for the holiday.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                        <th className="col-completed">
                            Completed
                            <Tooltip title="The total number of orders (packages) that have been fully produced and are ready.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                        <th className="col-processing">
                            Processing
                            <Tooltip title="The total number of orders (packages) that are currently in production.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                        <th className="col-due">
                            Due
                            <Tooltip title="The total number of orders (packages) that are in production or have been produced but are past the production deadline.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                        <th className="col-notPush">
                            Not Push
                            <Tooltip title="The total number of orders (packages) that have not yet been pushed to the factory.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                        <th className="col-percentage">
                            Percentage
                            <Tooltip title="The completion percentage of production within the total orders that need to be cut off for the holiday.">
                                <InfoCircleOutlined className='ml-2 cursor-pointer'/>
                            </Tooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!!reportData && reportData.length > 0 ? reportData.map((item, i) => {
                        return <ReportCutoffTimeRow
                            key={`ReportCutoffTimeRowComponent_${i}`}
                            index={i}
                            item={item}
                        />
                    }) : <tr>
                        <td colSpan={8}>No data</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ReportCutoffTime
