import React, {Component} from 'react'
import Clipboard from "react-clipboard.js"
import formatCurrency from "../../../../helpers/common/formatCurrency"
import PropTypes from "prop-types"
import { Tag, Modal as AntdModal, Button, Tooltip } from 'antd'
import ObjectInspector from 'react-object-inspector'
import classNames from 'classnames'
import Modal from "reactstrap/es/Modal"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"

class FulfilledPackageItemProduct extends Component {
    state = {
        copyProduct: "Copy product title",
        showModalUpdated : false,
        showModalMockup : false,
    }

    _handleCopyProductTitle = () => {
        this.setState({
            copyProduct: "Copied!",
        })

        setTimeout(() => {
            this.setState({
                copyProduct: "Copy product title",
            })
        }, 2000)
    }

    handleOpenModal = () => {
        this.setState({
            showModalUpdated: true,
        })
    }

    handleCancel = () => {
        this.setState({
            showModalUpdated: false,
        })
    }

    _handleToggleModalMockup = () => {
        this.setState({
            showModalMockup: !this.state.showModalMockup,
        })
    }

    render() {
        const {fulfilledPackageId, product, variant, price, quantity, sku, productLabel, currency, orderItem, rowSpan} = this.props
        const {variant_histories, mockup_url, mockup_message, merchizeCatalogSku, platformRuleSku, rule_name} = {...orderItem}
        const {copyProduct, showModalUpdated, showModalMockup} = this.state
        const {sku: skuVariant} = Object.assign({}, variant)
        const vSku = skuVariant || sku || ''

        const options = platformRuleSku ? platformRuleSku.options : (merchizeCatalogSku ? merchizeCatalogSku.options : [])
        const product_title = platformRuleSku ? platformRuleSku.product_title : (merchizeCatalogSku ? merchizeCatalogSku.product_title : '')
        const plfRuleSku = platformRuleSku ? platformRuleSku.sku : ''
        const tooltipVariantCatalog = platformRuleSku ? 'Info based on PLF rule SKU!' : 'Info based on PLF SKU!'

        return (
            <td className="FulfilledPackageItemProduct" rowSpan={rowSpan}>
                <div className="row">
                    <div className="col-4">
                        <img src={product.preview} alt="Product thumbnail"/>

                        <div className='thumbnail-bottom'>
                            <Tooltip title="View Mockup">
                                <div className={classNames('cursor-pointer', 'mt-2', 'mockup-open', {'disable' : mockup_message || !mockup_url})} onClick={this._handleToggleModalMockup}>
                                    <i className="far fa-image"></i>
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="col-8">
                        <div className="mb-2">
                            <a href={product.url} target="_blank" rel="noopener noreferrer"
                               className="mr-3 text-break">{product.title}</a>

                            <Clipboard data-clipboard-text={product.title} component="span"
                                       className="d-inline-block cursor-pointer"
                                       onSuccess={this._handleCopyProductTitle}>
                                <div className="Tooltip3 Top">
                                    <i className="far fa-copy"/>

                                    <div className="TooltipContent Clipboard">
                                        <div className="ContentInner text-center">
                                            <small className="text-monospace">{copyProduct}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                            {!!variant_histories && !!variant_histories.length &&
                                <Tag
                                    color="#87d068"
                                    className='position-absolute'
                                    style={{right:'0', cursor: 'pointer'}}
                                    onClick={this.handleOpenModal}
                                >Updated</Tag>
                            }
                            <AntdModal
                                visible={showModalUpdated}
                                title="Updating histories"
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button key="back" onClick={this.handleCancel}>
                                        Close
                                    </Button>
                                ]}
                            >
                                <table className="table table-striped">
                                    <tbody>
                                        {!!variant_histories && !!variant_histories.length && variant_histories.map((i, index) => (
                                            <tr key={index}><td><ObjectInspector depth={0} path="root.value" data={i} /></td></tr>
                                        ))}
                                    </tbody>
                                </table>
                            </AntdModal>
                        </div>

                        {
                            productLabel !== 'branding' && <div className="d-flex mb-1">
                                <span className="Label font-weight-500">Price:</span>
                                <span>{formatCurrency(currency, price)}</span>
                            </div>
                        }

                        {
                            !!vSku &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">SKU:</span>
                                <span className='text-break'>{vSku}</span>
                            </div>
                        }

                        {
                            !!plfRuleSku &&
                            <div className="d-flex mb-1">
                                <span className="Label font-weight-500">PLF SKU:</span>
                                <span className='text-break'>{plfRuleSku}</span>
                            </div>
                        }

                        <div className="Quantity d-flex">
                            <span className="Label font-weight-500">Quantity:</span>
                            <span className="Number">{quantity}</span>
                        </div>
                    </div>

                    <div className={`${platformRuleSku || merchizeCatalogSku ? 'col-6 mt-3 border-right' : 'col-12 mt-3'}`}>
                        <div className='CustomVariant'>
                            <span className="Label">Variant:</span>
                            <span className='text-break'>{variant.title}</span>
                        </div>
                        <div className='CustomVariable'>
                            <ul>
                                {
                                    variant.options.length > 0 && variant.options.map(item =>
                                        <li className="d-flex mb-1" key={item.key + item.value + fulfilledPackageId}>
                                            <span className="Label mr-1">{item.key}:</span>
                                            <span className={`text-break ${item.key}`}>{item.value}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    {
                        (platformRuleSku || merchizeCatalogSku ) ?
                        <div className="col-6 mt-3">
                            <div className='CustomVariant'>
                                <Tooltip title={tooltipVariantCatalog}>
                                    <span className="Label">Catalog variant</span>
                                </Tooltip>
                            </div>
                            <div className='CustomVariable'>
                                <ul>
                                    {
                                        product_title &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Product Title:</span>
                                            <span className={`text-break`}>{product_title}</span>
                                        </li>
                                    }

                                    {
                                        options && options.length > 0 &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Variant:</span>
                                            <span className={`text-break`}>{options.join(" / ")}</span>
                                        </li>
                                    }

                                    {
                                        rule_name &&
                                        <li className="mb-1">
                                            <span className="Label mr-1">Rule Name:</span>
                                            <span className={`text-break`}>{rule_name}</span>
                                        </li>
                                    }

                                </ul>
                            </div>
                        </div> : ''
                    }

                    {
                        mockup_message &&
                        <div className='col-12 mt-3 mockup-message'>
                            <i className="fas fa-exclamation-triangle"></i> {mockup_message}
                        </div>
                    }
                </div>

                {
                    mockup_url && !mockup_message &&
                    <Modal className='modal-mockup' isOpen={showModalMockup} toggle={this._handleToggleModalMockup}>
                        <ModalBody>
                            <div className='mockup-image'>
                                <img src={mockup_url} alt={product.title}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-end">
                                <button className="btn border" onClick={this._handleToggleModalMockup}>Cancel</button>
                            </div>
                        </ModalFooter>
                    </Modal>
                }
            </td>
        )
    }
}

FulfilledPackageItemProduct.propTypes = {
    fulfilledPackageId: PropTypes.string,
    product: PropTypes.object,
    variant: PropTypes.object,
    price: PropTypes.number,
    quantity: PropTypes.number,
    sku: PropTypes.object,
    productLabel: PropTypes.string,
    currency: PropTypes.string
}

export default FulfilledPackageItemProduct
