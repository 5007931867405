import React, { useState, useEffect } from 'react'
import { message, Spin, Select, Input, Tooltip } from "antd"
import SettingHolidayDetailTable from './SettingHolidayDetailTable'
import { Link } from 'react-router-dom'
import { getHolidaySetting, updateHolidaySetting } from '../../../services/api/SuppliersServices'
import { searchProducts } from '../../../services/api/ProductsServices'
import { InfoCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

function SettingHolidayDetailPage(props) {
    const { idSetting } = { ...props }
    const [UTCTime, setUTCTime] = useState(7)
    const [preCutoff, setPreCutoff] = useState(null)
    const [currentPreCutoff, setCurrentPreCutoff] = useState(null)
    const [listSettingDetail, setListSettingDetail] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const [supplierDetail, setSupplierDetail] = useState({})
    const [sortType, setSortType] = useState('down')
    const [errorValidate, setErrorValidate] = useState([])
    const [productTypeAllows, setProductTypeAllows] = useState([])
    const [productTypeOptions, setProductTypeOptions] = useState([])

    const fetchSettingDetail = async () => {
        setLoading(true)
        delete error.message
        setSortType('')
        try {
            const { data, success, message: mess } = await getHolidaySetting(idSetting)

            if (success) {
                const { holidays = [], supplier = {} } = { ...data }
                const newHolidays = holidays.map(holiday => {
                    const { cutoff_settings = [] } = Object.assign({}, holiday)

                    const cutoff_settings_default = [
                        {
                            priority: 1,
                            cutoff_time: null,
                            isUsing: 'yes',
                            isSave: true,
                            store: {
                                allow: [],
                                disallow: [],
                                isExpanded: false,
                                target: 'allow'
                            },
                            product_type: {
                                allow: [],
                                disallow: [],
                                isExpanded: false,
                                target: 'allow'
                            },
                        },
                        {
                            isUsing: 'no',
                            priority: 2,
                        },
                        {
                            isUsing: 'no',
                            priority: 3,
                        }
                    ]

                    const newCutoffSettings = cutoff_settings.map(cutoffSetting => {
                        const { store = {}, product_type = {} } = Object.assign({}, cutoffSetting)
                        return {
                            ...cutoffSetting,
                            isSave: true,
                            isUsing: 'yes',
                            store: {
                                ...store,
                                target: store.disallow.length > 0 ? 'disallow' : 'allow'
                            },
                            product_type: {
                                ...product_type,
                                target: product_type.disallow.length > 0 ? 'disallow' : 'allow'
                            }
                        }
                    })

                    const sortCutoffSetting = newCutoffSettings.sort((a, b) => a.priority - b.priority)
                    return {
                        ...holiday,
                        cutoff_settings: sortCutoffSetting.length > 0 ?
                            (sortCutoffSetting.length < 3 ? [...sortCutoffSetting, ...renderItem(3 - sortCutoffSetting.length)] : sortCutoffSetting) : cutoff_settings_default
                    }
                })

                const sortHolidays = newHolidays.length > 0 ? newHolidays.sort((a, b) => {
                    if (!a.start_time) return 1
                    if (!b.start_time) return -1
                    return sortType === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
                }) : []
                const holidaysHavePreCutoff = newHolidays.length > 0 && newHolidays[0]
                const PreCutoffDays = holidaysHavePreCutoff ? holidaysHavePreCutoff.pre_cutoff_days : null
                setListSettingDetail(sortHolidays)
                setCurrentData(sortHolidays)
                setSupplierDetail(supplier)
                setUTCTime(newHolidays.length > 0 ? newHolidays[0].time_zone : 7)
                setPreCutoff(PreCutoffDays)
                setCurrentPreCutoff(PreCutoffDays)
                if (Object.keys(supplier).length > 0 && !!supplier._id) _fetchProducts(supplier._id)

            } else {
                message.error(mess)
            }
            setLoading(false)
        } catch (error) {
            setError({ ...error, message: error.message || 'Unknown error.' })
        } finally {
            setLoading(false)
        }
    }

    const renderItem = number => {
        let arr = []
        for (let i = 1; i <= number; i++) {
            arr.push({ isUsing: 'no', priority: number === 1 ? 3 : i + 1 })
        }

        return arr
    }

    const _fetchProducts = async (supplierId) => {
        try {
            const payload = {
                supplier: supplierId,
                limit: 5000
            }

            const { data, success, message: mess } = await searchProducts(payload)

            if (!success) return message.error(mess)

            const { products = [] } = data
            const newProducts = products.map(product => product.type)
            setProductTypeAllows(newProducts)
            setProductTypeOptions(products)
        } catch (e) {
            message.error(e.message)
        }
    }

    useEffect(() => {
        fetchSettingDetail()
    }, [idSetting])

    const handleChangeUTC = (value) => {
        setUTCTime(value)
    }

    const handleChangePreCutoff = (e) => {
        delete error.preCutoff
        const value = e.target.value
        if (value || value === 0) {
            if (value <= 0) {
                setError({ ...error, preCutoff: 'Minimum value is 1' })
                setPreCutoff(null)
            } else {
                setPreCutoff(value)
            }
        } else {
            setPreCutoff(null)
        }
    }

    const handleAddSetting = (isSettingCutoff = false, index) => {
        setListSettingDetail(preSetting => {
            if (!isSettingCutoff) {
                return (
                    [
                        ...preSetting,
                        {
                            name: "",
                            idx: uuidV4(),
                            start_time: null,
                            end_time: null,
                            cutoff_time: null,
                            pre_cutoff_days: null,
                            cutoff_settings: [
                                {
                                    priority: 1,
                                    cutoff_time: null,
                                    isSave: false,
                                    isUsing: 'yes',
                                    store: {
                                        allow: [],
                                        disallow: [],
                                        isExpanded: false
                                    },
                                    product_type: {
                                        allow: [],
                                        disallow: [],
                                        isExpanded: false
                                    },
                                },
                                {
                                    priority: 2,
                                    isUsing: 'no',
                                },
                                {
                                    priority: 3,
                                    isUsing: 'no',
                                },
                            ]
                        }
                    ]
                )
            }

            const newListSettingDetail = preSetting.map((setting, i) => {
                if (i === index) {
                    const { cutoff_settings = [] } = Object.assign({}, setting)
                    const listCutoffSetting = cutoff_settings.sort((a, b) => {
                        if (a.isUsing === "yes" && b.isUsing !== "yes") return -1
                        if (a.isUsing !== "yes" && b.isUsing === "yes") return 1

                        if (a.priority !== undefined && b.priority !== undefined) {
                            return b.priority - a.priority
                        }
                        return 0
                    })

                    const newSettings = [
                        ...listCutoffSetting,
                        {
                            priority: (!!listCutoffSetting[0] && !!listCutoffSetting[0].priority) ? +listCutoffSetting[0].priority + 1 : cutoff_settings.length + 1,
                            cutoff_time: null,
                            isSave: false,
                            isUsing: 'yes',
                            store: {
                                allow: [],
                                disallow: [],
                                isExpanded: false
                            },
                            product_type: {
                                allow: [],
                                disallow: [],
                                isExpanded: false
                            },
                        }
                    ]

                    const uniqueCutoffSetting = newSettings.filter((item, index, self) => {
                        if (item.isUsing === "no") {
                            return !self.some((otherItem, otherIndex) =>
                                otherIndex !== index && otherItem.priority === item.priority && otherItem.isUsing === "yes"
                            )
                        }
                        return true
                    })

                    const newCutoffSettings = uniqueCutoffSetting.sort((a, b) => {
                        if (a.priority && b.priority) {
                            return a.priority - b.priority
                        }

                        if (a.priority) return -1
                        if (b.priority) return 1
                        return 0
                    })

                    return {
                        ...setting,
                        cutoff_settings: newCutoffSettings
                    }
                }
                return setting
            })
            return newListSettingDetail
        })
    }

    const handleRemoveSetting = (index) => {
        const newList = listSettingDetail.filter((i, idx) => idx !== index)
        setListSettingDetail(newList)

    }

    const handleRemoveSettingCutoff = (index, priority) => {
        const newList = listSettingDetail.map((i, idx) => {
            if (idx === index && priority) {
                const { cutoff_settings = [] } = Object.assign({}, i)

                if ([1, 2, 3].includes(priority)) {
                    const newCutoffSettings = cutoff_settings.map((item, o) => {
                        if (item.priority === priority) {
                            return { ...item, isUsing: 'no' }
                        }
                        return item
                    }) || []

                    const listCutoffSettingUsing = newCutoffSettings.filter((o) => o.isUsing && o.isUsing === 'yes').map((u, t) => ({ ...u, priority: t + 1 }))

                    return {
                        ...i,
                        cutoff_settings: listCutoffSettingUsing.length < 3 ? [...listCutoffSettingUsing, ...renderItem(3 - listCutoffSettingUsing.length)] : listCutoffSettingUsing
                    }
                }

                const newSetting = cutoff_settings.filter(o => o.priority !== priority) || []
                return {
                    ...i,
                    cutoff_settings: newSetting.map((item, o) => ({ ...item, priority: o + 1 }))
                }
            }

            return i
        })
        setListSettingDetail(newList)

    }

    const handleChangeSetting = (index, field, value, priority, target, selectField = false) => {
        setError({})
        setErrorValidate([])
        let listUpdated = []

        listSettingDetail.map((i, idx) => {
            if (idx === index) {
                if (field === 'holidayDate') {

                    return listUpdated.push({
                        ...i,
                        start_time: value && value.length > 0 ? value[0].utcOffset(0, true).toISOString() : null,
                        end_time: value && value.length > 0 ? value[1].utcOffset(0, true).toISOString() : null,
                    })
                }

                if (field === "shipping_date") {
                    return listUpdated.push({
                        ...i,
                        [field]: value ? value.utcOffset(0, true).toISOString() : null
                    })
                }

                if (field === "cutoff_tags") {
                    return listUpdated.push({
                        ...i,
                        [field]: value ? [value] : []
                    })
                }

                if (!!priority) {
                    const { cutoff_settings = [] } = Object.assign({}, i)
                    const newCutoffSetting = cutoff_settings.map(setting => {
                        if (priority === setting.priority) {
                            if (field === "cutoff_time") {
                                return {
                                    ...setting,
                                    [field]: value ? value.utcOffset(0, true).toISOString() : null,
                                    isSave: false
                                }
                            }

                            if (selectField) {
                                return {
                                    ...setting,
                                    [field]: {
                                        allow: [],
                                        disallow: [],
                                        isExpanded: false,
                                        target: value
                                    }
                                }
                            }

                            if (field === "storeExpand") {
                                return {
                                    ...setting,
                                    store: {
                                        ...setting.store,
                                        isExpanded: value
                                    }
                                }
                            }

                            if (field === "productTypeExpand") {
                                return {
                                    ...setting,
                                    product_type: {
                                        ...setting.product_type,
                                        isExpanded: value
                                    }
                                }
                            }

                            return {
                                ...setting,
                                [field]: {
                                    ...setting[field],
                                    [target]: value
                                }
                            }
                        }

                        return setting
                    })
                    return listUpdated.push({ ...i, cutoff_settings: newCutoffSetting })
                }

                return listUpdated.push({ ...i, [field]: value })
            }

            return listUpdated.push(i)
        })

        setListSettingDetail(listUpdated)
    }

    const getOptionUTCTime = (start, end, another) => {
        const Options = []
        for (let i = start; i <= end; i++) {
            const Option = {
                name: (i >= 0 ? `UTC +` : `UTC -`) + Math.abs(i).toString().padStart(2, '0') + ":00",
                value: i,
            }
            Options.push(Option)
        }
        const anotherTimeZone = [
            { name: 'UTC -09:30', value: -9.5 },
            { name: 'UTC -03:30', value: -3.5 },
            { name: 'UTC +03:30', value: -3.5 },
            { name: 'UTC +04:30', value: 4.5 },
            { name: 'UTC +05:30', value: 5.5 },
            { name: 'UTC +05:45', value: 5.75 },
            { name: 'UTC +06:30', value: 6.5 },
            { name: 'UTC +08:45', value: 8.75 },
            { name: 'UTC +09:30', value: 9.5 },
            { name: 'UTC +10:30', value: 10.5 },
            { name: 'UTC +12:45', value: 12.75 },
        ]
        return another ? [...Options, ...anotherTimeZone].sort((a, b) => a.value - b.value) : Options
    }

    const validate = () => {
        setErrorValidate([])
        let isValid = true

        if (listSettingDetail && listSettingDetail.length > 0) {
            listSettingDetail.forEach((i, index) => {
                const { cutoff_settings = [] } = Object.assign({}, i)
                const newCutoffSetting = cutoff_settings.filter(o => !!o.isUsing && o.isUsing === 'yes')
                let today = moment().utc()
                let errors = {}
                let isPast = moment(today).isSameOrAfter(moment(i.end_time))

                if (!i.name) {
                    errors.name = 'Please input Holiday name!!!'
                } else if (i.name.length > 100) {
                    errors.name = 'Maximum 100 characters.'
                }

                // Kiểm tra ngày bắt đầu
                if (!i.start_time) {
                    // errors.holiday_date = 'Please select Holiday date!'
                    isValid = false
                    return message.error(`Holiday name ${i.name}. Please select Holiday date!`)
                }

                if (Object.keys(errors).length > 0) {
                    errors.row = index
                    setErrorValidate(prevErrors => [...prevErrors, errors])
                    isValid = false
                }

                // Kiểm tra pre_cutoff_days
                if (!!i.pre_cutoff_days && !isPast) {
                    if (!i.pre_cutoff_days || isNaN(i.pre_cutoff_days) || i.pre_cutoff_days < 0) {
                        setError({ ...error, preCutoff: 'Please input Pre-Cutoff days!' })
                    }
                }

                const listConditionStoreAnhType = newCutoffSetting.filter(setting => {
                    const { store = {}, product_type = {} } = Object.assign({}, setting)
                    return store.allow.length > 0 || store.disallow.length > 0 || product_type.allow.length > 0 || product_type.disallow.length > 0
                }) || []

                // Kiểm tra product type phải thuộc Supplier đang cài đặt
                const isDisAllowProductType = newCutoffSetting.some(setting => {
                    const { product_type = {} } = Object.assign({}, setting)
                    const { disallow = [], allow = [] } = Object.assign({}, product_type)
                    const listType = !!disallow.length ? disallow : allow

                    return !!listType.length && listType.some(p => !productTypeAllows.includes(p))
                })

                if (isDisAllowProductType) {
                    isValid = false
                    return message.error(`Holiday name ${i.name}. Product type must belong to the supplier being installed.`)
                }

                // Kiểm tra có từ 2 ngày cutoff date trở lên, bắt buộc có tối thiểu (n-1) cutoff date có điều kiện store HOẶC Product type (với n là số lượng ngày cutoff date của holiday) 
                if (newCutoffSetting.length > 1 && (newCutoffSetting.length - listConditionStoreAnhType.length) > 1) {
                    isValid = false
                    return message.error(`Holiday name ${i.name}. There need to be conditions regarding stores and product types when there are 2 or more cutoff date.`)
                }
            })

            return isValid
        }

        return isValid
    }

    const handleSave = async () => {
        const payload = {
            time_zone: UTCTime,
            pre_cutoff_days: preCutoff
        }
        if (!validate()) return

        if (isChangeData(listSettingDetail, currentData)) {
            const configs = listSettingDetail.length > 0 ? listSettingDetail.map(i => {
                const { cutoff_settings = [], ...rest } = Object.assign({}, i)

                const objectPayload = {
                    ...rest,
                    start_time: i.start_time ? moment(i.start_time).utcOffset(0).format('DD/MM/YYYY') : null,
                    end_time: i.end_time ? moment(i.end_time).utcOffset(0).format('DD/MM/YYYY') : null,
                    shipping_date: i.shipping_date ? moment(i.shipping_date).utcOffset(0).format('DD/MM/YYYY HH:mm') : null,
                }

                const newCutoffSettings = cutoff_settings.map(o => {
                    const { isSave, isUsing, cutoff_time, ...rest } = Object.assign({}, o)
                    const itemSetting = { ...rest }
                    if (!!cutoff_time) itemSetting.cutoff_time = moment(cutoff_time).utcOffset(0).format('DD/MM/YYYY HH:mm')

                    if (!!isUsing && isUsing === 'yes') return itemSetting
                }).filter(Boolean) || []

                if (newCutoffSettings.length === 1 && newCutoffSettings.some(c => {
                    const {cutoff_time = '', product_type = {}, store = {}} = Object.assign({}, c)
                    return !!cutoff_time || !!product_type.allow.length || !!store.allow.length || !!product_type.disallow.length || !!store.disallow.length
                })) objectPayload.cutoff_settings = newCutoffSettings

                if(newCutoffSettings.length > 1) objectPayload.cutoff_settings = newCutoffSettings
                
                const newObj = Object.fromEntries(Object.entries({ ...objectPayload }).filter(([_, value]) => !!value))
                return newObj
            }).filter(obj => Object.keys(obj).length !== 0) : []
            payload.configs = configs
        }

        try {
            const { data, success, message: mess } = await updateHolidaySetting(idSetting, payload)
            if (!success) {
                throw new Error(mess)
            }
            message.success(`Setting Holiday ${currentData.length ? 'updated' : 'created'}!!!`)
            fetchSettingDetail()
        } catch (error) {
            setError({ ...error, message: error.message })
            message.error(error.message)
        }
    }

    const handleSort = (type) => {
        if (listSettingDetail && listSettingDetail.length > 0) {
            setSortType(type)
            const sortData = listSettingDetail.slice().sort((a, b) => {
                if (!a.start_time) return 1
                if (!b.start_time) return -1
                return type === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
            })

            const sortCurrentData = currentData.slice().sort((a, b) => {
                if (!a.start_time) return 1
                if (!b.start_time) return -1
                return type === 'up' ? new Date(a.start_time) - new Date(b.start_time) : new Date(b.start_time) - new Date(a.start_time)
            })
            setCurrentData(sortCurrentData)
            setListSettingDetail(sortData)
        }
    }

    const isChangeData = (data, currentdata) => {
        if (data.length !== currentdata.length) {
            return true
        }
        if (preCutoff !== currentPreCutoff) {
            return true
        }
        for (let i = 0; i < data.length; i++) {
            if (JSON.stringify(data[i]) !== JSON.stringify(currentdata[i])) {
                return true
            }
        }
        return false
    }

    const UTCOptions = getOptionUTCTime(-12, 14, true)
    const { Option } = Select
    const isEdit = listSettingDetail && listSettingDetail.length > 0 && listSettingDetail.some(obj => obj.hasOwnProperty('_id'))
    const today = moment().utc().add(UTCTime, 'hours').toISOString()

    const isDisablePreCutoffDays = listSettingDetail.some(item => {
        const { cutoff_settings = [] } = Object.assign({}, item)
        return cutoff_settings.some(setting => {
            const { cutoff_time = '', isSave = false } = Object.assign({}, setting)
            const cutoffDateSetting = moment(cutoff_time).subtract(Number(preCutoff), "days")
            const cutoffDateRunning = moment(today).isAfter(cutoff_time)
            const isCutoff = isSave && !cutoffDateRunning && moment(today).isBetween(cutoffDateSetting, cutoff_time)
            return isCutoff
        })
    })

    return (
        <div className='SettingHolidayDetailPage'>
            <div className='PageHeading d-flex align-items-center justify-content-between border-bottom'>
                <h1 className="PageTitle mt-2 mb-0">Setting Holiday - Supplier {supplierDetail.name || ''}</h1>
                <div className="RightTop">
                    <div className='Actions'>
                        <Link to='/a/setting-holiday' className='ant-btn ant-btn-default px-4 rounded mr-3 btn-secondary'>Cancel</Link>
                        <button
                            className='ant-btn ant-btn-primary px-4 rounded mr-3'
                            disabled={loading || !isChangeData(listSettingDetail, currentData)}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="SectionInner mt-4" style={{ minHeight: '775px' }}>
                <h5 className='mb-4'>Configure Holiday Date and Cutoff Date: </h5>
                <div className="filter align-items-center mt-2 mb-3">
                    <div className="filter-item mb-3 d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}>UTC Time:</label>
                        <Select
                            placeholder='Select UTC Time'
                            className='ml-2'
                            value={UTCTime}
                            showSearch
                            style={{ minWidth: '300px' }}
                            disabled={loading || isEdit}
                            onChange={handleChangeUTC}
                        >
                            {
                                UTCOptions.map((item, index) =>
                                    <Option key={index} value={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>
                    <div className="filter-item d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}>Pre-Cutoff Days: </label>
                        <Input
                            placeholder='Pre-Cutoff days'
                            className='ml-2'
                            value={!preCutoff ? null : preCutoff}
                            style={{ maxWidth: '150px' }}
                            disabled={isDisablePreCutoffDays}
                            onChange={handleChangePreCutoff}
                            type='number'
                            allowClear
                            min={1}
                        >
                        </Input>
                        <label className='ml-2 mb-0'>
                            Calendar days
                            <Tooltip title="The cutoff period is determined from (cutoff date - pre-cutoff days) to the cutoff date." placement="topLeft">
                                <InfoCircleFilled className="ml-2 cursor-pointer" />
                            </Tooltip>
                        </label>
                    </div>
                    <div className="filter-item d-flex align-items-center">
                        <label className='font-weight-bold mb-0' style={{ minWidth: '127px' }}></label>
                        {error.preCutoff && <small className='text-danger ml-2 mt-2'>{error.preCutoff}</small>}
                    </div>
                </div>
                <div>
                    <Spin spinning={loading} tip='Data Loading...'>
                        <SettingHolidayDetailTable
                            listSettingDetail={listSettingDetail || []}
                            UTCTime={UTCTime}
                            currentData={currentData}
                            loading={loading}
                            reload={fetchSettingDetail}
                            addSetting={handleAddSetting}
                            removeSetting={handleRemoveSetting}
                            onRemoveSettingCutoff={handleRemoveSettingCutoff}
                            handleChangeSetting={handleChangeSetting}
                            error={error}
                            handleSort={handleSort}
                            sortType={sortType}
                            errorValidate={errorValidate}
                            preCutoff={preCutoff}
                            setListSettingDetail={setListSettingDetail}
                            productTypeOptions={productTypeOptions}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default SettingHolidayDetailPage