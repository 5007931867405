import createApiServices from "./createApiServices"
import queryString from 'query-string'

const prefixURL = process.env.NODE_ENV === 'production' ? '/api/supplier' : 'https://fulfillment-staging.merchize.com/api/supplier'
const api = createApiServices({baseUrl: prefixURL})
const devURL = window['API_SUPPLIER_SERVICE_URL'] || ''

export const getListSuppliers = (query, useCache = false) => {
    const cacheKey = `__getListSuppliers_${JSON.stringify(query)}`

    const callApi = () => api.makeAuthRequest({
        url: `/suppliers`,
        method: 'GET',
        params: query
    }).then(response => {
        window[cacheKey] = response
        // Clear cache after 30 minutes
        setTimeout(() => {
            delete window[cacheKey]
        }, 30 * 60 * 60 * 1000)

        return response
    })

    if (useCache && window[cacheKey]) {
        return Promise.resolve(window[cacheKey])
    }

    return callApi()
}

export const getListSuppliersV2 = (params) => {
    const query = queryString.stringify(params)

    return api.makeAuthRequest({
        url: `/v2/suppliers?${query}`,
        method: 'GET'
    })
}

export const getSuppliersSupportingCostReconciliation = (params) => {
    const query = queryString.stringify(params)

    return api.makeAuthRequest({
        url: `/suppliers-supporting-cost-reconciliation?${query}`,
        method: 'GET'
    })
}

export const createSupplier = (data) => {
    return api.makeAuthRequest({
        url: `/suppliers`,
        method: 'POST',
        data
    })
}

export const updateSupplier = (supplierId, data) => {
    return api.makeAuthRequest({
        url: `/suppliers/${supplierId}`,
        method: 'PUT',
        data
    })
}

export const deleteSupplier = (supplierId) => {
    return api.makeAuthRequest({
        url: `/suppliers/${supplierId}`,
        method: 'DELETE'
    })
}

export const getShipmentReport = () => {
    return api.makeAuthRequest({
        url: `/v2/report-shipment`,
        method: 'GET'
    })
}

export const getCompletedProduction = (supplierId, params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/v2/report-production-time/${supplierId}?${query}`,
        method: 'GET'
    })
}


export const getCompletedProductionByStore = (supplierId, params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/v2/report-production-time/by-store/${supplierId ? supplierId : ''}?${query}`,
        method: 'GET'
    })
}

export const getReportProductionTime = (params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/report-production-time/?${query}`,
        method: 'GET'
    })
}

export const uploadFileCSV = (file) => {
    return api.makeAuthRequest({
        url: `/check-cross/upload`,
        method: 'POST',
        data: file
    })
}

export const getListCostControl = (payload) => {
    return api.makeAuthRequest({
        url: `/check-cross/invoices-search`,
        method: 'POST',
        data: payload
    })
}

export const fileApproveReject = (id, payload) => {
    return api.makeAuthRequest({
        url: `/check-cross/${id}/do-approval`,
        method: 'POST',
        data: payload
    })
}

export const getListFileDetails = (id, params) => {
    return api.makeAuthRequest({
        url: `/supplier-invoices/details/${id}`,
        method: 'GET',
        params: params
    })
}

export const exportListFileDetails = (id, params) => {
    return api.makeAuthRequest({
        url: `/supplier-invoices/export-results/${id}`,
        method: 'GET',
        params: params
    })
}
export const getListOrderProfit = (params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/supplier-invoices/orders-profit/`,
        method: 'POST',
        data: params
    })
}

export const exportListOrderProfit = (params, filename) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/supplier-invoices/orders-profit/csv`,
        method: 'POST',
        data: params
    })
}

export const exportListPackagesProfit = (params, filename) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/supplier-invoices/orders-profit/csv/packages`,
        method: 'POST',
        data: params
    })
}

export const gettListOrderProfitDetails = (id, params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `/supplier-invoices/orders-profit/${id}`,
        method: 'GET',
        data: params
    })
}

export const getShipmentReportTiktok = () => {
    return api.makeAuthRequest({
        url: `/v2/report-shipment-tiktok`,
        method: 'GET'
    })
}

export const getCompletedProductionTiktok = (params) => {
    console.log('paramsss: ', params)
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/order-tiktok/report-production-time/?${query}`,
        method: 'GET'
    })
}

export const getCompletedProductionTiktokByStore = (params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/order-tiktok/report-production-time/by-store?${query}`,
        method: 'GET'
    })
}

export const getListHolidaySetting = (params) => {
    return api.makeAuthRequest({
        url: `${devURL}/holiday-setting/search`,
        method: 'POST',
        data: params
    })
}
export const getHolidaySetting = (id) => {
    return api.makeAuthRequest({
        url: `${devURL}/holiday-setting/${id}`,
        method: 'GET'
    })
}
export const updateHolidaySetting = (id, payload) => {
    return api.makeAuthRequest({
        url: `${devURL}/holiday-setting/upsert/${id}`,
        method: 'POST',
        data: payload,
    })
}

export const getCompletedFastProduction = (supplierId, params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/fast-production/report-production-time/${supplierId}?${query}`,
        method: 'GET'
    })
}


export const getCompletedFastProductionByStore = (supplierId, params) => {
    const query = queryString.stringify(params)
    return api.makeAuthRequest({
        url: `${devURL}/fast-production/report-production-time/by-store/${supplierId ? supplierId : ''}?${query}`,
        method: 'GET'
    })
}

export const getIssueTypeReport = (params, supplierId, typeReport) => {
    const query = queryString.stringify(params)

    return api.makeAuthRequest({
        url: `/fulfillment-issue-report/${typeReport}/${supplierId}?${query}`,
        method: 'GET'
    })
}

export const getIssueProductTypeReport = (params, supplierId) => {
    const query = queryString.stringify(params)

    return api.makeAuthRequest({
        url: `/fulfillment-issue-report/by-product/${supplierId}?${query}`,
        method: 'GET'
    })
}

export const getIssueStorePackageReport = (data, supplierId) => {
    return api.makeAuthRequest({
        url: `/fulfillment-issue-report/by-store-and-package/${supplierId}`,
        method: 'POST',
        data
    })
}

export const getIssueStorePackageItemReport = (data, supplierId) => {
    return api.makeAuthRequest({
        url: `/fulfillment-issue-report/by-store-and-package-item/${supplierId}`,
        method: 'POST',
        data
    })
}

export const getCutoffReport = () => {
    return api.makeAuthRequest({
        url: `/report-cutoff`,
        method: 'GET'
    })
}

export const getListPackage = (params) => {
    return api.makeAuthRequest({
        url: `/report-cutoff/get-list-package`,
        method: 'POST',
        data: params
    })
}