import React from 'react'

function SettingHolidayHeader() {
    return (
        <thead className="SettingHolidayHeader">
            <tr>
                <th>Supplier</th>
                <th>Holiday Name</th>
                <th>Holiday Date</th>
                <th>Cutoff Date</th>
                <th>Store</th>
                <th>Product Type</th>
                <th>Pre-Cutoff Days</th>
                <th>UTC Time</th>
                <th>Actions</th>
            </tr>
        </thead>
    )
}

export default SettingHolidayHeader