import React, { useState, useEffect } from "react"
import { getVariants } from "../../../services/api/ProductsServices"
import moment from "moment"
import { Pagination, Switch, Spin } from "antd"
import ExportVariants from "./ExportVariants"
import VariantsFilters from "./VariantsFilters";
import { getListSuppliersV2 } from "../../../services/api/SuppliersServices"
import {Link} from "react-router-dom"

const Variants = () => {
  const [variants, setVariants] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(50)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({ term: '', is_disabled: '', supplier: '', catalog_mapping_status: '', available_tiktok : '', onlyFindWithProductAvailable: false })
  const [optionSupplier, setOptionSupplier] = useState([])

  const _handleChangePagination = (page, pageSize) => {
    setPage(pageSize !== limit ? 1 : page)
    setLimit(pageSize)
  }

  const _handleChangeFilter = (paramFilter) => {
    setFilters(filters => ({ ...filters, ...paramFilter }))
    setPage(1)
  }

  const fetchVariants = async () => {
    setLoading(true)
    setError("")
    setVariants([])
    try {
      const { term, is_disabled, supplier, stock_alert, catalog_mapping_status, available_tiktok = '', onlyFindWithProductAvailable = false } = filters
      let payload = { page, limit, query: {} }
      if (is_disabled) {
        payload.query.variant_status = is_disabled === 'false' ? '1' : is_disabled === 'true' ? '0' : ''
      }
      if (supplier !== '') payload.query.supplier = supplier
      if (term !== '') payload.query.product_title = term
      if (stock_alert) payload.query.stock_alert = '1'
      if (catalog_mapping_status) payload.query.catalog_mapping_status = catalog_mapping_status
      if (available_tiktok !== '') payload.query.available_tiktok = available_tiktok
      if(!!onlyFindWithProductAvailable) payload.onlyFindWithProductAvailable = 1

      const response = await getVariants(payload)
      const { success, data, message } = response
      if (!success) {
        setLoading(false)
        setError(message)
      }
      setVariants(data.variants)
      setTotal(data.total)
      setLoading(false)
    }
    catch (e) {
      setLoading(false)
      setError(e.message)
    }
  }

  const getSuppliers = async () => {
    const { data } = await getListSuppliersV2({ active: true })
    const optionsSupplier = data.suppliers.filter(i => i && i.sku_prefix && !i.is_deleted)
    setOptionSupplier(optionsSupplier)
  }

  const getVariantsSize = (option) => {
    const Size = option.filter(i => i.attribute.name === "Size")
    return Size && Size.length > 0 ? Size.map((option, index) => (option.name)) : ''
  }

  const getVariantsColor = (option) => {
    const Color = option.filter(i => i.attribute.name === "Color")
    return Color && Color.length > 0 ? Color.map((option, index) => (option.name)) : ''
  }

  useEffect(() => {
    const { term } = filters
    if (term.length == 0 || term.length >= 3) {
      fetchVariants()
      getSuppliers()
    }
  }, [page, limit, filters])

  return (
    <div className="Variants">
      <div className="d-flex align-items-center VariantHeader">
        <h1 className="PageTitle">List Variants</h1>
        <ExportVariants
          filters={filters}
          suppliers={optionSupplier}
        />
      </div>
      <div className="SectionInner">
        <div className="align-items-center ShowTotal d-flex justify-content-between mt-2 mb-2">
          <div className="mb-3">
            <VariantsFilters onChangeFilter={_handleChangeFilter} filters={filters} optionSupplier={optionSupplier} />
          </div>
          <div className="mb-3"><b>Total: </b>{total} items</div>
        </div>
        <Spin spinning={loading} tip="Getting list ...">
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th>Product title</th>
                <th>Type</th>
                <th>Size</th>
                <th>Color</th>
                <th>SKU</th>
                <th>SKU partner</th>
                <th>Stock Alert</th>
                <th>Available Qty</th>
                <th>Restock date</th>
                <th>Mapping status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!!variants &&
                variants.length > 0 &&
                variants.map((item, index) => {
                  return (
                    <tr key={item._id} style={{ background: item.stock_alert ? 'rgba(255, 0, 0, 0.2)' : 'none' }}>
                      <td>
                        <Link to={`/a/products/${item.product._id}`} target="_blank">
                          {item.product.title}
                        </Link>
                      </td>
                      <td>{item.product.type}</td>
                      <td>
                        {item.options && item.options.length > 0 &&
                          getVariantsSize(item.options)}
                      </td>
                      <td>
                        {item.options && item.options.length > 0 &&
                          getVariantsColor(item.options)
                        }
                      </td>
                      <td>{item.sku} </td>
                      <td>{item.partner_sku}</td>
                      <td style={{ textAlign: 'center' }}>{item.pushed_count && item.pushed_count.stock_alert_value ? Math.ceil(item.pushed_count.stock_alert_value) : ''}</td>
                      <td className="text-center">{
                        (item.remaining_stock_quantity !== null || item.remaining_stock_quantity !== undefined) ?
                          item.remaining_stock_quantity : ''
                      }</td>
                      <td>
                        {item.restock_date ? moment(item.restock_date).format("DD/MM/YYYY") : ""}
                      </td>
                      <td>
                        {item.catalog_mapping_status === 'mapped' ?
                          <span className="badge badge-success">Mapped</span> :
                          <span className="badge badge-danger">Unmapped</span>
                        }
                      </td>
                      <td>
                        <Switch checked={!item.is_disabled} />
                      </td>
                    </tr>
                  )
                })}

              {!!error && error.length > 0 && (
                <tr>
                  <td style={{ color: "red" }} colSpan={7}>
                    {error}
                  </td>
                </tr>
              )}

              {!!variants && variants.length === 0 && (
                <tr>
                  <td colSpan={9}>No record</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            current={page}
            total={total}
            pageSize={limit}
            onChange={_handleChangePagination}
            className="text-right"
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
          />
        </Spin>
      </div>
    </div>
  )
}

export default Variants
